import React, { useState } from "react";
import FilterWrapper from "./FilterWrapper";
import FilterHeader from "./FilterHeader";
import CustomAccordion from "./CustomAccordion";
import ReusableRadioGroup from "./ReusableRadioGroup";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import FilterClearDoneButton from "./FilterClearDoneButton";
import { useDispatch } from "react-redux";
import { removeQuery } from "../../../utils/queryHelpers";
import { useSearchParams } from "react-router-dom";
import DynamicFilter from "./DynamicFilter";

const CustomFilter = ({
  toggleDrawer = () => {},
  filterOptions,
  handleClear = () => {},
}) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  if (!filterOptions) return "--";
  return (
    <FilterWrapper>
      <FilterHeader
        onToggleDrawer={(anchor, bool, e) => {
          toggleDrawer(anchor, bool)(e);
        }}
      />

      {filterOptions.length > 0 &&
        filterOptions.map(
          (
            {
              state,
              options,
              setState,
              summryText,
              date,
              time,
              parentDispatch,
              type,
              conditionalState,
              inputlabel,
              inputstate,
              inputSetState,
              ignorefilter,
              filterName,
            },
            index
          ) =>
            ignorefilter ? null : (
              <CustomAccordion summary={summryText} key={index}>
                <DynamicFilter
                  type={type}
                  state={state}
                  dispatch={dispatch}
                  parentDispatch={parentDispatch}
                  setState={setState}
                  options={options}
                  date={date}
                  time={time}
                  conditionalState={conditionalState}
                  inputlabel={inputlabel}
                  inputstate={inputstate}
                  inputSetState={inputSetState}
                />
                <Button
                  sx={{
                    color: "#000",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (type === "input radio") {
                      inputSetState("");
                    }

                    if (state && filterName) {
                      removeQuery(filterName, setSearchParams);
                    }

                    if (state) {
                      parentDispatch ? dispatch(setState("")) : setState("");

                      if (conditionalState?.state) {
                        conditionalState?.conditionIsDispatch
                          ? dispatch(conditionalState?.setState(""))
                          : conditionalState?.setState("");
                      }
                    }
                    if (date && date?.customDateState) {
                      const dateState = {};

                      Object.entries(date?.customDateState ?? {}).forEach(
                        ([key, _]) => {
                          dateState[key] = null;
                        }
                      );

                      date?.isDispatch
                        ? dispatch(date.setState(dateState))
                        : date.setState(dateState);
                    }

                    if (time && typeof time === "object") {
                      time?.setState((prev) => {
                        const newState = {};
                        Object.entries(prev).forEach(([key, _]) => {
                          newState[key] = null;
                        });
                        return newState;
                      });
                    }
                  }}
                >
                  Clear
                </Button>
              </CustomAccordion>
            )
        )}

      <FilterClearDoneButton
        onDone={toggleDrawer("right", false)}
        onClear={handleClear}
        filterOptions={filterOptions}
      />
    </FilterWrapper>
  );
};

export const SortPanel = ({
  toggleDrawer = () => {},
  sortOptions,
  handleClear = () => {},
  onSortChange = () => {},
}) => {
  const [sortState, setSortState] = useState({});

  if (!sortOptions) return "--";

  const handleSortChange = (field, order) => {
    setSortState(prevState => {
      const newState = { ...prevState, [field]: order };
      if (!order) delete newState[field];
      onSortChange(newState);
      return newState;
    });
  };

  return (
    <FilterWrapper>
      <FilterHeader
      text ="Sort By"
        onToggleDrawer={(anchor, bool, e) => {
          toggleDrawer(anchor, bool)(e);
        }}
      />

      {sortOptions.length > 0 &&
        sortOptions.map(({ field, label }, index) => (
          <CustomAccordion summary={label} key={index}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sortState[field] === 'asc'}
                    onChange={(e) => handleSortChange(field, e.target.checked ? 'asc' : null)}
                  />
                }
                label="Ascending"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sortState[field] === 'desc'}
                    onChange={(e) => handleSortChange(field, e.target.checked ? 'desc' : null)}
                  />
                }
                label="Descending"
              />
            </FormGroup>
            <Button
              sx={{
                color: "#000",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleSortChange(field, null);
              }}
            >
              Clear
            </Button>
          </CustomAccordion>
        ))}

      <FilterClearDoneButton
        onDone={toggleDrawer("right", false)}
        onClear={() => {
          setSortState({});
          handleClear();
        }}
        text = "Sort"
        sortOptions={sortOptions}
      />
    </FilterWrapper>
  );
};


export default CustomFilter;
