import { initializeApp } from "firebase/app";
import { getRemoteConfig, getAll, fetchAndActivate } from "firebase/remote-config";

// specified firebase project configuration object from the firebase website
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// initialize the firebaseApp instance
const app = initializeApp(firebaseConfig);

// gets the project's remote config template
const remoteConfig = getRemoteConfig(app);

  // function that returns remote config values as key-value pairs containing the config name and its value
  const getFirebaseRemoteConfig = async () => {  
    // fetches and activates the fetched config
    await fetchAndActivate(remoteConfig);
    console.log("Remote Config fetched from Firebase.");
    
    // gets all config
    const remoteConfigValues = getAll(remoteConfig);
    
    // initialize the array that will contain the config values
    const configValues = {};
    for (const key in remoteConfigValues){
        if (remoteConfigValues.hasOwnProperty(key)){
            var innerKey = remoteConfigValues[key];
            var value = innerKey["_value"];
            configValues[key] = value;
        }
    }
    return configValues;
  };
  // export the function
  export { getFirebaseRemoteConfig };
