import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  fabClasses,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BlockIcon from "@mui/icons-material/Block";
import styled from "@emotion/styled";
import ArticleIcon from "@mui/icons-material/Article";
import axiosInstance from "../utils/axiosConfig";
import useDebounce from "../hooks/useDebounce";
import CustomConfirmationDialog from "./CustomConfirmationDialog";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import filterEmptyPropertiesRecursively from "../utils/filterEmptyPropertiesRecursively";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import SearchActionComponent from "./reusableComponents/SearchActionComponent";
import CustomFilter, { SortPanel } from "./reusableComponents/Filter/CustomFilter";
import { BootstrapTooltip } from "./DetailsSidePanelChart";
import { useLocation, useSearchParams } from "react-router-dom";
import formatNumberShort, { validateNumber } from "../utils/formatNumberShort";
import { useSettings } from "../hooks/useSettings";
import MuiIconButton from "./uicomponents/MuiIconButton";
import DataTable from "./DataTable";
import FilteredResults from "./FilteredResults";
import CustomerDetails from "./CustomerDetails";
import { removeQuery, setQuery } from "../utils/queryHelpers";
import { getCountryNamesAndCodes } from "../const/countrylist";
import { countries } from "countries-list";

// on hovering tooltip component
function CustomComponent({ openTickets, totalTickets }) {
  return (
    <Box
      sx={{
        display: "inline-flex",
        padding: "5px",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        gap: "2px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
        }}
      >{`Open tickets - ${openTickets}`}</Typography>
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
        }}
      >{`Total tickets - ${totalTickets}`}</Typography>
    </Box>
  );
}

export function TripsComponent({ completeTrips, cancelledTrips, totalTrips }) {
  return (
    <Box
      sx={{
        display: "inline-flex",
        padding: "5px",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        gap: "2px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
        }}
      >{`Complete trips - ${completeTrips}`}</Typography>
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
        }}
      >{`Cancelled trips - ${cancelledTrips}`}</Typography>
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
        }}
      >{`Total trips - ${totalTrips}`}</Typography>
    </Box>
  );
}

// custom menu
export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    zIndex: "-1",
    borderRadius: 6,
    marginTop: "10px",
    minWidth: 180,
    color: "#000",
    boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.07)",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        color: "#000",
        marginRight: "1.5px",
      },
    },
  },
}));

function Action({
  params,
  toggleDrawer,
  setRiderId,
  handleClick,
  open,
  permissionsSubSection,
  anchorEl,
  handleClose,
  handleConformationDailog,
  isblocked,
  setIsBlocked,
  hideViewDetails = true,
  setRiderName,
}) {
  // css
  const menuIteamBtnStyle = {
    color: "#000",
    fontFamily: "Proxima Nova",
    fontSize: "16px",
    width: "100%",
    fontStyle: "normal",
    justifyContent: "flex-start",
    fontWeight: 500,
    lineHeight: "175%",
  };

  return (
    <>
      <MuiIconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => {
          setIsBlocked(params?.row?.account_status ?? "");
          handleClick(e, params.row.id);
          setRiderName(params?.row?.full_name);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MoreVertIcon
          sx={{
            color: "#000",
          }}
        />
      </MuiIconButton>
      <>
        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          ModalProps={{
            style: {
              zIndex: 1200,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          {hideViewDetails && (
            <MenuItem>
              <Button
                sx={menuIteamBtnStyle}
                disableRipple
                disableFocusRipple
                disableElevation
                variant="text"
                startIcon={<ArticleIcon sx={{ color: "#000" }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                  toggleDrawer("right", true)(e);
                }}
              >
                View details
              </Button>
            </MenuItem>
          )}
          {permissionsSubSection?.UPDATE && (
            <MenuItem>
              <Button
                sx={menuIteamBtnStyle}
                disableRipple
                disableFocusRipple
                disableElevation
                variant="text"
                startIcon={<BlockIcon sx={{ color: "#000" }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                  handleConformationDailog("block", true);
                }}
              >
                {isblocked === "ACTIVE" ? "Block" : "Unblock"}
              </Button>
            </MenuItem>
          )}

          {permissionsSubSection?.DELETE && (
            <MenuItem>
              <Button
                sx={menuIteamBtnStyle}
                disableRipple
                disableFocusRipple
                disableElevation
                variant="text"
                startIcon={<DeleteIcon sx={{ color: "#000" }} />}
                onClick={(e) => {
                  handleClose();
                  handleConformationDailog("delete", true);
                }}
              >
                Delete
              </Button>
            </MenuItem>
          )}
        </StyledMenu>
      </>
    </>
  );
}

const Customers = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const idParam = searchParams.get("id");
  const [riderId, setRiderId] = useState(idParam || "");
  const [riderName, setRiderName] = useState("");
  const { pathname } = useLocation();

  const allowed_permissions = useSelector(
    (store) => store.rolePermission.allowed_permissions
  );

  const permissions = allowed_permissions[pathname];

  // dialog state
  const [conformationDialog, setConformationDialog] = useState({
    delete: false,
    block: false,
  });

  const tab = searchParams.get("tab");
  const [accountStatus, setAccountStatus] = useState(() => {
    if (permissions) {
      return tab || permissions?.SUB_SECTIONS[0];
    } else {
      return tab || "ACTIVE";
    }
  });

  // State for managing the filter
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });

  const [joinedon, setJoinedOn] = useState("");
  const [ticket, setTickets] = useState("");
  const [trips, setTrips] = useState("");

  const [isblocked, setIsBlocked] = useState("");

  const handleAccountStatus = (status) => {
    setQuery("tab", status, setSearchParams);
    setQuery("page", 0, setSearchParams);
    setQuery("limit", 10, setSearchParams);
    setAccountStatus(status);
  };

  const [showFilter, SetShowFilter] = useState({ right: false });
  const [showSort, SetShowSort] = useState({ right: false });

  const limit = parseInt(searchParams.get("limit"), 10) || 10;
  const page = parseInt(searchParams.get("page"), 0) || 0;

  // control mui pagination
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: limit || 10,
    page: page || 0,
  });

  const customerFilter = searchParams.get("customerdrawer");
  const [showDriverDetails, setDriverDetails] = useState({
    right: customerFilter || false,
  });

  const [gender, setGender] = useState("");

  const [customer, setCustomer] = useState([]);

  // Pagination state
  const [totalCount, setTotalCount] = useState({
    ACTIVE: 0,
    BLOCKED: 0,
    DELETE: 0,
  });

  // Debounce
  const searchDebounce = useDebounce(searchValue, 500);

  const { dateformat, timezone, formatDate } = useSettings();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const [countycode, setCountryCode] = useState(null);

  const handleClick = (event, riderId) => {
    setAnchorEl(event.currentTarget);
    setRiderId(riderId);

    setSearchParams(searchParams, { replace: true });
  };

  const handleConformationDailog = (label, value) => {
    setConformationDialog((prev) => ({ ...prev, [label]: value }));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkQuery = (download) => {
    const query = [];

    if (searchDebounce && searchValue) {
      query.push(`search=${searchDebounce}`);
    }
    if (download) {
      query.push(`is_download=${true}`);
    }

    if (gender) {
      query.push(`gender=${gender}`);
    }

    if (countycode) {
      const country_code = countries[countycode?.value].phone;
      query.push(`country_code=${country_code}`);
    }

    if (joinedon) {
      query.push(`joined_on=${joinedon}`);
    }

    if (date && date.endDate) {
      let startDate = `${date.startDate.year()}-${date.startDate.month()}-${date.startDate.date()}`;
      let endDate = `${date.endDate.year()}-${date.endDate.month()}-${date.endDate.date()}`;
      query.push(`date_gte=${startDate}&date_lte=${endDate}`);
    }

    if (ticket) {
      query.push(`tickets_sort=${ticket}`);
    }

    if (trips) {
      query.push(`trips_sort=${trips}`);
    }

    let qurys = query.length > 1 ? query.join("&") : query.join("");

    return qurys;
  };

  const handleOnChange = (event, newValue) => {
    setCountryCode(newValue);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDriverDetails({ ...showDriverDetails, [anchor]: open });
  };

  const toggleCustomerDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    SetShowFilter({ ...showDriverDetails, [anchor]: open });
  };
   const toggleSortDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    SetShowSort({ ...showDriverDetails, [anchor]: open });
  };

  // Function To Fetch Coustomer Data
  const fetchCustomerData = async (alt = "", status) => {
    setisLoading(true);
    let query = checkQuery(status);

    let url = `/crm/rider/?page=${paginationModel.page + 1}&limit=${
      paginationModel.pageSize
    }&account_status=${alt ? alt : accountStatus}&${query}`;
    try {
      await axiosInstance.get(url).then((res) => {
        if (status) {
          if (res) {
            showSuccessToast(
              "file has been successfully sent via Email. Check your Email account",
              {
                autoClose: 5000,
              }
            );
          }
          return;
        }

        setCustomer(res?.data?.data?.records ?? []);

        setTotalCount((prev) => ({
          ...prev,
          [accountStatus]: res.data.data.total_count,
        }));
      });
    } catch (error) {
      let message = error?.response?.data?.message;
      if (message) {
        showErrorToast(error);
      }
    } finally {
      setisLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "phone",
        headerName: "Phone Number",
        renderCell: (params) => {
          if (!params?.value) {
            return "--";
          }

          let phoneNumber = params.value;
          let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);
          return (
            <>
              <Button
                id={params.id}
                sx={{
                  color: "#043A87",
                  width: "86px",
                  fontWeight: "500",
                  textAlign: "center",
                  fontFamily: "Proxima Nova",
                  fontSize: "13px",
                  textTransform: "capitalize",
                  border: "none",
                  paddingLeft: "30px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setRiderId(params.id);
                  setQuery("id", params?.id, setSearchParams);
                  setQuery("customerdrawer", true, setSearchParams);
                  let callback = toggleDrawer("right", true);
                  callback(e);
                }}
              >
                {number}
              </Button>
            </>
          );
        },
        flex: 0.7,
      },
      {
        field: "full_name",
        headerName: "Name",
        flex: 0.7,
        renderCell: (params) => {
          if (!params?.value) {
            return "--";
          }

          return (
            <>
              <BootstrapTooltip title={params?.value}>
                <Button
                  id={params.id}
                  sx={{
                    border: "none",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: "vertical",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRiderId(params.id);

                    setQuery("id", params?.id, setSearchParams);
                    setQuery("customerdrawer", true, setSearchParams);

                    let callback = toggleDrawer("right", true);
                    callback(e);
                  }}
                >
                  <Typography
                    sx={{
                      color: "#043A87",
                      fontFamily: "Proxima Nova",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "13px",
                      textTransform: "capitalize",
                      letterSpacing: "0.26px",
                      fontWeight: "500",
                    }}
                  >
                    {params?.value}
                  </Typography>
                </Button>
              </BootstrapTooltip>
            </>
          );
        },
      },
      {
        field: "nick_name",
        headerName: "Nick name",
        flex: 0.7,
        renderCell: (params) => {
          if (!params?.value) {
            return "--";
          }

          return (
            <>
              <BootstrapTooltip title={params?.value}>
                <Button
                  id={params.id}
                  sx={{
                    border: "none",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: "vertical",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRiderId(params.id);
                    setQuery("id", params?.id, setSearchParams);
                    setQuery("customerdrawer", true, setSearchParams);
                    let callback = toggleDrawer("right", true);
                    callback(e);
                  }}
                >
                  <Typography
                    sx={{
                      color: "#043A87",
                      fontFamily: "Proxima Nova",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "13px",
                      textTransform: "capitalize",
                      letterSpacing: "0.26px",
                      fontWeight: "500",
                    }}
                  >
                    {params?.value}
                  </Typography>
                </Button>
              </BootstrapTooltip>
            </>
          );
        },
      },
      {
        field: "created_at",
        headerName: `Joined On(${dateformat})`,
        flex: 0.5,
        formatter: {
          created_at: (params) =>
            formatDate(params?.value, timezone, dateformat),
          // time: ( params) => {formatDate(params?.row.created_at, timezone, timeFormat??)},
          rating: (params) => `(${params?.value})`,
          // Add other formatters as needed
        },
        valueGetter: (params) => {
          if (!params.value) {
            return "--";
          }

          return `${formatDate(params?.value, timezone, dateformat)}`;
        },
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        valueGetter: (param) => {
          if (!param.value) {
            return "--";
          }

          return param.value;
        },
      },
      {
        field: "gender",
        headerName: "Gender",
        flex: 0.5,
        valueGetter: (params) => {
          if (!params.value) {
            return "--";
          }
          return params.value;
        },
      },
      {
        field: "tickets",
        headerName: "Tickets",
        flex: 0.5,
        renderCell: (param) => {
          if (
            !param?.row?.open_tickets_count &&
            param?.row?.total_tickets_count
          ) {
            return "--";
          }

          return (
            <BootstrapTooltip
              key={param.row.id}
              title={
                <CustomComponent
                  openTickets={param?.row?.open_tickets_count ?? ""}
                  totalTickets={param?.row?.total_tickets_count ?? ""}
                />
              }
              arrow
              placement="bottom-end"
            >
              <Typography
                variant="h6"
                sx={{
                  color: "var(--Linked, #043A87)",
                  fontFamily: "Proxima Nova",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "0.26px",
                }}
              >
                {`${param.row.open_tickets_count}/${param?.row?.total_tickets_count}`}
              </Typography>
            </BootstrapTooltip>
          );
        },
      },
      {
        field: "completed_trips_count",
        headerName: "Trips",
        flex: 0.5,
        renderCell: (param) => {
          return (
            <BootstrapTooltip
              key={param.row.id}
              title={
                <TripsComponent
                  completeTrips={validateNumber(
                    param?.row?.completed_trips_count
                  )}
                  cancelledTrips={validateNumber(
                    param?.row?.cancelled_trips_count
                  )}
                  totalTrips={validateNumber(param?.row?.total_trips_count)}
                />
              }
              arrow
              placement="bottom-end"
            >
              <Typography
                variant="h6"
                sx={{
                  color: "var(--Linked, #043A87)",
                  fontFamily: "Proxima Nova",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "0.26px",
                }}
              >
                {`${param?.row?.completed_trips_count ?? 0}/${
                  param?.row?.cancelled_trips_count ?? 0
                }`}
              </Typography>
            </BootstrapTooltip>
          );
        },
      },
      {
        field: "id",
        headerName: "Action",
        flex: 0.2,
        renderCell: (params) => {
          return (
            <Action
              key={params.id}
              permissionsSubSection={permissions ?? {}}
              params={params}
              toggleDrawer={toggleDrawer}
              setRiderId={setRiderId}
              handleClick={handleClick}
              open={open}
              anchorEl={anchorEl}
              setRiderName={setRiderName}
              handleClose={handleClose}
              uniqueBehavior="specific-behavior"
              handleConformationDailog={handleConformationDailog}
              isblocked={params?.row?.account_status}
              setIsBlocked={setIsBlocked}
            />
          );
        },
      },
    ],
    [
      toggleDrawer,
      setRiderId,
      handleClick,
      open,
      anchorEl,
      handleClose,
      conformationDialog,
      setIsBlocked,
    ]
  );

  // Funtion to handle Delete
  const DeleteRiderById = async (e) => {
    try {
      await axiosInstance.delete(`/crm/rider/${riderId}/`).then((_) => {
        showSuccessToast("rider account has been deleted successfully!");
        fetchCustomerData();
      });
    } catch (error) {
      showErrorToast(error);
      console.log("Error while Delete Rider by id", error);
    }
  };

  // Funtion To Block Rider By Id
  const handleBlock = () => {
    let updateData = filterEmptyPropertiesRecursively({
      account_status: isblocked === "ACTIVE" ? "BLOCKED" : "ACTIVE",
    });
    try {
      axiosInstance.patch(`/crm/rider/${riderId}/`, updateData).then((_) => {
        showSuccessToast(
          `The account has been successfully ${
            isblocked === "BLOCKED" ? "unblocked" : "blocked"
          }!`
        );

        fetchCustomerData("ACTIVE");
        if (accountStatus !== "ACTIVE") {
          setAccountStatus("ACTIVE");
        }
      });
    } catch (error) {
      showErrorToast(error);
      console.log(error, "Error while updating customer");
    }
  };

  // Funtion to handle Block
  const customeDateHandler = (name, value, setState) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  // Funtion to Clear All filter
  const handleClear = () => {
    if (joinedon) {
      setJoinedOn("");
    }

    if (date.startDate || date.endDate) {
      setDate({
        startDate: "",
        endDate: "",
      });
    }

    if (gender) {
      setGender("");
    }

    if (ticket) {
      setTickets("");
    }

    if (trips) {
      setTrips("");
    }
  };

  // config
  const allowed_sub_sections = permissions?.SUB_SECTIONS ?? [
    "ACTIVE",
    "BLOCKED",
    "DELETE",
    "FLAGGED",
  ];

  const sub_section_to_label = {
    ACTIVE: "Active",
    BLOCKED: "Blocked",
    DELETE: "Delete",
    FLAGGED: "Flagged",
  };

  const tableHeadButtonConfig = allowed_sub_sections?.map((sub_section) => {
    return {
      label: sub_section_to_label[sub_section],
      value: sub_section,
      totalcount: totalCount[sub_section],
      handleAccountStatus,
    };
  });

  const countrylist = useMemo(() => getCountryNamesAndCodes());

  const customerFilterOptions = [
    {
      summryText: "Joined on",
      state: joinedon,
      setState: setJoinedOn,
      options: [
        { label: "Yesterday", value: "yesterday" },
        { label: "Last Week", value: "last_week" },
        { label: "Last Month", value: "last_month" },
        { label: "Last Year", value: "last_year" },
      ],
      date: {
        customDateState: date,
        customDateHeader: "Custom",
        customDateLabel: ["From", "To"],
        customDateName: ["startDate", "endDate"],
        customeDateHandler,
        setState: setDate,
        isoString: true,
        // isDispatch: true,
      },
    },
    {
      type: "select",
      summryText: "Select a Country Code",
      options: countrylist,
      state: countycode,
      setState: handleOnChange,
    },
    {
      summryText: "Gender",
      state: gender,
      setState: setGender,
      options: [
        { label: "Male", value: "MALE" },
        { label: "Female", value: "FEMALE" },
      ],
    },
    {
      summryText: "Tickets",
      state: ticket,
      setState: setTickets,
      options: [
        { label: "Max Tickets", value: "max_ticktes" },
        { label: "Min Tickets", value: "min_tickets" },
        { label: "Max Pending", value: "max_pending" },
        { label: "Min Pending", value: "min_pending" },
        { label: "Resolved", value: "resolved" },
      ],
    },
    {
      summryText: "Trips",
      state: trips,
      setState: setTrips,
      options: [
        { label: "Max Trips", value: "max_trips" },
        { label: "Min Trips", value: "min_trips" },
        { label: "Max Cancelled", value: "max_cancelled" },
        { label: "Min Cancelled", value: "min_cancelled" },
      ],
    },
  ];

  const handleDownload = () => {
    fetchCustomerData("", "download");
  };

  useEffect(() => {
    fetchCustomerData();
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    searchDebounce,
    accountStatus,
    gender,
    ticket,
    trips,
    date.startDate,
    date.endDate,
    joinedon,
    countycode,
  ]);

  return (
    <>
      <Box></Box>
      <Box m="1.5rem 1.5rem">
        <SearchActionComponent
          value={searchValue}
          setState={setSearchValue}
          require={{ search: true, filter: true, download: true , sort : false }}
          toggleDrawer={toggleCustomerDrawer}
          toggleSortDrawer= {toggleSortDrawer}
          downloadFile={handleDownload}
        />

        <FilteredResults options={customerFilterOptions} />

        <Box
          sx={{
            mt: "15px",
            display: "flex",
            gap: "0.25rem",
          }}
        >
          {tableHeadButtonConfig.length > 0 &&
            tableHeadButtonConfig.map((res) => (
              <Button
                key={res.label}
                sx={{
                  display: "flex",
                  width: "142px",
                  padding: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "25px",
                  borderRadius: "10px 10px 0 0",
                  border: "1px solid #929EAE",
                }}
                style={{
                  background:
                    accountStatus === res.value ? "lightgray" : "#FFF",
                }}
                onClick={() => {
                  res.handleAccountStatus(res.value);
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Proxima Nova",
                    textAlign: "right",
                    fontWeight: "600",
                    lineHeight: "normal",
                    letterSpacing: "0.28px",
                    textTransform: "capitalize",
                    color: "#000",
                  }}
                >
                  {res.label}
                </Typography>

                {accountStatus === res.value && res.totalcount > 0 && (
                  <Box
                    sx={{
                      display: "inline-flex",
                      padding: "2px 5px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      background: "#000",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "right",
                        fontFamily: "Proxima Nov",
                        fontSize: "14px",
                        lineHeight: "normal",
                        fontWeight: "600px",
                        letterSpacing: "0.28px",
                        textTransform: "capitalize",
                      }}
                    >
                      {formatNumberShort(
                        accountStatus === res.value ? res.totalcount : 0
                      )}
                    </Typography>
                  </Box>
                )}
              </Button>
            ))}
        </Box>

        <DataTable
          key={accountStatus}
          row={customer || []}
          columns={columns}
          sx={{ cursor: "pointer" }}
          paginationModel={paginationModel}
          loading={isLoading}
          setPaginationModel={setPaginationModel}
          totalCount={Math.ceil(totalCount[accountStatus])}
        />
      </Box>

      {showDriverDetails.right && (
        <SwipeableDrawerComponent
          isOpen={showDriverDetails["right"]}
          anchor="right"
          onClose={(e) => {
            removeQuery("customerdrawer", setSearchParams);
            removeQuery("id", setSearchParams);
            toggleDrawer("right", false)(e);
          }}
          ModalProps={{
            style: {
              zIndex: 1800,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <CustomerDetails
            toggleDrawer={toggleDrawer}
            anchor={"right"}
            riderId={riderId}
            setRiderId={setRiderId}
            fetchFunction={fetchCustomerData}
          />
        </SwipeableDrawerComponent>
      )}

      {showFilter.right && (
        <SwipeableDrawerComponent
          isOpen={showFilter["right"]}
          onClose={toggleCustomerDrawer("right", false)}
          anchor="right"
        >
          <CustomFilter
            filterOptions={customerFilterOptions}
            toggleDrawer={toggleCustomerDrawer}
            handleClear={handleClear}
          />
        </SwipeableDrawerComponent>
      )}
       {showSort.right && (
        <SwipeableDrawerComponent
          isOpen={showSort["right"]}
          onClose={toggleSortDrawer("right", false)}
          anchor="right"
        >
          <SortPanel
            filterOptions={customerFilterOptions}
            toggleDrawer={toggleSortDrawer}
            sortOptions={[ { field: "name", label: "Name" },
              { field: "date", label: "Date" },
              { field: "price", label: "Price" },
              { field: "status", label: "Status" }]}
            handleClear={handleClear}
          />
        </SwipeableDrawerComponent>
      )}

      <CustomConfirmationDialog
        open={conformationDialog?.delete}
        isGroup={true}
        message={`Are you sure you want to delete ${riderName}`}
        status="delete"
        deleteById={DeleteRiderById}
        handleState={handleConformationDailog}
      />

      <CustomConfirmationDialog
        open={conformationDialog?.block}
        isGroup={true}
        message={`Are you Sure you Want to ${
          isblocked !== "ACTIVE" ? "unblock" : "block"
        } ${riderName}`}
        status="block"
        blockById={handleBlock}
        handleState={handleConformationDailog}
      />
    </>
  );
};

export default Customers;
