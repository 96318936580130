import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  IconButton,
  Checkbox,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import FlexBetween from "./FlexBetween";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FileUploadInput from "./FileUploadInput";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import filterObjectsWithProperties from "../utils/filterObjectsWithProperties";
import calculateAge from "../utils/calculateAge";
import { toast } from "react-toastify";
import axiosInstance from "../utils/axiosConfig";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import CustomConfirmationDialog from "./CustomConfirmationDialog";
import DriverBasicDetails from "./DriverBasicDetails";
import { handleCheckboxChange } from "../utils/fileValidation";
import GenericFileUpload from "./GenericFileUpload";
import filterEmptyPropertiesRecursively from "../utils/filterEmptyPropertiesRecursively";
import CommonSaveButton from "./driverDetails/CommonSaveButton";
import { getFileType } from "../utils/fileUtilis";
import CommonAutoComplete from "./CommonAutoComplete";
import DriverTrainingDetails from "./DriverTrainingDetails";
import axios from "axios";
import usePermission from "../hooks/usePermission";
import { useSettings } from "../hooks/useSettings";

const initialDriverStats = {
  total_distance_travelled_in_km: 0,
  total_cancelled_rides: 0,
  total_rides: 0,
  total_earnings: 0,
};

//'COMPACT', 'TAXI', 'EXECUTIVE', 'LUXURY'
const carTypeCategory = [
  {
    value: "COMPACT",
    label: "COMPACT",
  },
  {
    value: "TAXI",
    label: "TAXI",
  },
  {
    value: "PLUS",
    label: "PLUS",
  },
];

const DriverDetails = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const permission = usePermission("/driver/:id");

  const [onboardingid, setOnboardingId] = useState({});
  const [isDriverVerified, setIsDriverVerified] = useState(false);
  const [peekupPoints, setPeekupPoint] = useState(null);

  const idRef = useRef({
    address_id: "",
    driving_license_id: "",
    nbi_certificate_id: "",
    covid_19_certificate_id: "",
    car_details_or_id: "",
    car_details_cr_id: "",
    car_details_insurance_id: "",
    ltfrb_id_id: "",
    bank_details_id: "",
    bank_document_ids: {},
    car_details_id: "",
    car_image_ids: {},
    fit_to_work_id: "",
  });

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [tranningCenter, setTrainingCenter] = useState([]);

  const [driveStats, setDriveStats] = useState(initialDriverStats);

  // custom confiorm

  const handleMenuClick = (e) => {
    switch (e.target.innerText) {
      case "Delete Driver":
        setOpenDeleteModal(true);
        break;
      case "Block Driver":
        setOpenBlockModel(true);
        break;
      case "Unblock Driver":
        setOpenBlockModel(true);
        break;
      default:
        break;
    }
  };

  // conformation state
  const [openDeleteModal, setOpenDeleteModal] = useState("");
  const [openBlockModel, setOpenBlockModel] = useState("");

  const [bankNameOptions, setBankNameOptions] = useState([]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState({
    getDriverDetails: false,
    documents: false,
    carDetails: false,
    bankDetails: false,
  });
  const [DriverDetails, setDriverDetails] = useState();
  const [driverImageID, setDriverImageId] = useState({
    image: "",
    driverNewId: "",
  });

  const { id } = useParams();
  const [profilePicture, setProfilePicture] = useState({
    updateID: "",
    url: "",
  });
  const [basicDetails, setBasicDetails] = useState({
    name: "",
    email: "",
    nickName: "",
    gender: "",
    dob: "",
    address1: "",
    address2: "",
    address3: "",
    cityCode: "",
    phoneNumber: "",
    accountStatus: "",
    operator_full_name: "",
    TIN: "",
    tnvsCheck: "",
  });

  // document state
  const [document1, setDocument1] = useState({
    url: "",
    document_front_extension: "",
  });
  const [document2, setDocument2] = useState({
    url: "",
    document_front_extension: "",
  });
  const [document3, setDocument3] = useState({
    url: "",
    document_front_extension: "",
  });
  const [document4, setDocument4] = useState({
    url: "",
    document_front_extension: "",
    document_Back_extension: "",
    documentBackUrl: "",
  });
  const [document5, setDocument5] = useState({
    url: "",
    document_front_extension: "",
  });
  const [document6, setDocument6] = useState({
    url: "",
    document_front_extension: "",
  });
  const [carDocument1, setCarDocument1] = useState({
    url: "",
    document_front_extension: "",
  });
  const [carDocument2, setCarDocument2] = useState({
    url: "",
    document_front_extension: "",
  });
  const [carDocument3, setCarDocument3] = useState({
    url: "",
    document_front_extension: "",
  });
  const [opertor, setOpertaor] = useState([]);
  const [driverType, setDriverType] = useState("");
  const [operatorName, setOpertaorName] = useState("");
  const [documentTitle] = useState([
    "Driving License",
    "NBI",
    "COVID-19",
    "LTFRB (Front)",
    "LTFRB (Back Optional)",
    "Fit to work",
  ]);

  const { formatDate, timezone, dateformat } = useSettings();

  const [carTitle] = useState([
    "Official Receipt (OR)",
    "Certificate of Registration (CR)",
    "Insurance",
  ]);

  const handleLoading = (name, value) => {
    if (!name) return;
    setLoading((prev) => ({ ...prev, [name]: value }));
  };

  const options = [`Block Driver`, "Delete Driver"];

  const documentRef = useRef({
    document1Id: "",
    document2Id: "",
    document3Id: "",
    document4Id: "",
    document5Id: "",
    document6Id: "",
  });

  const [newdoc1, setNewDoc1] = useState(null);
  const [newdoc2, setNewDoc2] = useState(null);
  const [newdoc3, setNewDoc3] = useState(null);
  const [newdoc4, setNewDoc4] = useState(null);
  const [newdoc5, setNewDoc5] = useState(null);
  const [newdoc6, setNewDoc6] = useState(null);
  const [carnewDoc1, setCarNewDoc1] = useState(null);
  const [carnewDoc2, setCarNewDoc2] = useState(null);
  const [carnewDoc3, setCarNewDoc3] = useState(null);
  const [banknewDoc, setBankNewDoc] = useState(null);
  const [approved, setApproved] = useState({
    id: "",
    is_verified: false,
  });

  const [basicDetailsCheck, setBasicDetailsCheck] = useState(false);
  const [documentsCheck, setDocumentsCheck] = useState(false);
  const [carDetailsCheck, setCarDetailsCheck] = useState(false);
  const [bankDetailsCheck, setBankDetailsCheck] = useState(false);

  const [slotOptions, setSlotOptions] = useState([]);

  const handleSlotOptions = (e) => {
    const { value } = e.target;

    const renderTime = slotOptions.find((res) => res.slot_key === value);

    setTrainingDetails((prev) => ({
      ...prev,
      reporting_time: renderTime?.render_time ?? "",
      slot_key: value,
      update: {
        ...prev.update,
        reporting_time: renderTime?.render_time ?? "",
        slot_key: value,
      },
    }));
  };

  const currentYear = new Date().getFullYear() + 2;

  const [driverDocument, setDriverDocument] = useState({});

  const [onboardingDate, setOnboardingDate] = useState("");

  const [carDetails, setCarDetails] = useState({
    carRegNumber: "",
    carColor: "",
    carModel: "",
    carYear: "",
    carCompany: "",
    carType: "",
    caseId: "",
  });

  const [bankDetails, setBankDetails] = useState({
    bankName: "",
    branchBSB: "",
    accountNumber: "",
    accountHolderName: "",
  });
  const [bankDocumentFile, setBankDocumentFile] = useState({
    url: "",
    bankdocId: "",
    document_front_extension: "",
  });

  // car images state
  const [carImage1, setCarImage1] = useState({
    title: "carImage1",
    id: "",
    image_id: "",
    old_id: "",
    new_id: "",
    url: "",
    document_front_extension: "",
  });
  const [carImage2, setCarImage2] = useState({
    title: "carImage2",
    id: "",
    image_id: "",
    old_id: "",
    new_id: "",
    url: "",
    document_front_extension: "",
  });
  const [carImage3, setCarImage3] = useState({
    title: "carImage3",
    id: "",
    old_id: "",
    image_id: "",
    new_id: "",
    url: "",
    document_front_extension: "",
  });
  const [carImage4, setCarImage4] = useState({
    title: "carImage4",
    id: "",
    old_id: "",
    image_id: "",
    new_id: "",
    url: "",
    document_front_extension: "",
  });
  const [carImage5, setCarImage5] = useState({
    title: "carImage5",
    id: "",
    old_id: "",
    image_id: "",
    new_id: "",
    url: "",
    document_front_extension: "",
  });

  const [apiCallMade, setApiCallMade] = useState(false);

  const [validateLtfrb, setValidateLtfrb] = useState(null);

  const handleCarImageChanges = (file, index) => {
    if (file) {
      const isValidFile = isFileExtensionValid(file.name);

      if (!isValidFile) {
        toast.error("Allowed file types: PDF, JPEG, JPG, PNG, HEIC, SVG.", {
          autoClose: 6000,
        });

        return;
      }
    } else {
      return;
    }

    const fileType = file?.type?.split("/")[1] ?? "";

    switch (index) {
      case 1:
        setCarImage1((prev) => ({
          ...prev,
          url: URL.createObjectURL(file),
          document_front_extension: fileType,
        }));
        uploadFileToApi(file, "carImg1");
        break;
      case 2:
        setCarImage2((prev) => ({
          ...prev,
          url: URL.createObjectURL(file),
          document_front_extension: fileType,
        }));
        uploadFileToApi(file, "carImg2");
        break;
      case 3:
        setCarImage3((prev) => ({
          ...prev,
          url: URL.createObjectURL(file),
          document_front_extension: fileType,
        }));
        uploadFileToApi(file, "carImg3");
        break;
      case 4:
        setCarImage4((prev) => ({
          ...prev,
          url: URL.createObjectURL(file),
          document_front_extension: fileType,
        }));
        uploadFileToApi(file, "carImg4");
        break;
      case 5:
        setCarImage5((prev) => ({
          ...prev,
          url: URL.createObjectURL(file),
          document_front_extension: fileType,
        }));
        uploadFileToApi(file, "carImg5");
        break;
      default:
        break;
    }
  };
  const age = useMemo(() => calculateAge(basicDetails.dob), [basicDetails.dob]);

  const [downloadCertificateCheck, setDownloadCertificateCheck] = useState({
    opertorType: "",
    tnvsCheck: undefined,
    download_certificate: false,
  });

  const [tranningDetails, setTrainingDetails] = useState({
    id: "",
    is_verified: false,
    traniningid: "",
    date: "",
    training_center_detail_id: "",
    appintment_detail_id: "",
    reporting_time: "",
    training_center_address: "",
    slot_key_id: "",
    training_center_name: "",
    auto_appoint: false,
    slot_key: "",
    update: {
      id: "",
      date: "",
      training_center_detail_id: "",
      appintment_detail_id: "",
      slot_key_id: "",
      reporting_time: "",
      training_center_address: "",
      training_center_name: "",
      auto_appoint: false,
      slot_key: "",
    },
  });

  const [status, setStatus] = useState({
    riderStatus: "",
    documentStatus: "",
    tranningStatus: "",
    approvalStatus: "",
  });

  const config = useSelector(
    (store) => store.config.configValues.server_base_url
  );

  // Function To Fetch Training Center
  const getTrainingCenter = async () => {
    let url = `${config}/crm/training-center-v2/`;

    try {
      return await axiosInstance.get(url).then((res) => {
        setTrainingCenter(res?.data?.data?.records ?? []);
        return res?.data?.data?.records ?? [];
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const downloadCertificate = async () => {
    try {
      const response = axiosInstance?.post("/crm/driver/certificate/");

      if (response) {
        showSuccessToast("Certificate successfully sent to your email!");
      }
    } catch (error) {}
  };

  const blockDriverByID = async () => {
    const updateData = {
      account_status:
        basicDetails.accountStatus === "BLOCKED" ? "ACTIVE" : "BLOCKED",
    };
    await axiosInstance
      .patch(`/crm/driver/${id}/`, updateData)
      .then((_) => {
        showSuccessToast(
          `Driver ${
            updateData.account_status === "BLOCKED" ? "Block" : "Unblock"
          } Successfully`
        );

        fetchUserData();
      })
      .catch((error) => {
        showErrorToast(error);
      });
  };

  const handleTrainingCenterAndSlotOptions = (value, tranningCenter) => {
    let trainingCenterObject = tranningCenter?.filter(
      (res) => res.id === value
    );
    if (trainingCenterObject?.length > 0) {
      trainingCenterObject = trainingCenterObject[0];
      const slot_name_and_default_capacity = Object.entries(
        trainingCenterObject?.metadata?.slot_name_and_default_capacity ?? {}
      );

      if (slot_name_and_default_capacity.length > 0) {
        const slotoptions = slot_name_and_default_capacity.reduce(
          (acc, [key, value]) => {
            const obj = {
              slot_key: key,
              render_name: value["render_name"],
              render_time: value["render_time"],
            };

            acc.push(obj);

            return acc; // Make sure to return the accumulator
          },
          []
        );

        setSlotOptions(slotoptions);
      }
    }

    return trainingCenterObject;
  };

  const handleTraningCenter = (e) => {
    const { value } = e.target;
    const trainingCenterObject = handleTrainingCenterAndSlotOptions(
      value,
      tranningCenter
    );

    setTrainingDetails((prev) => ({
      ...prev,
      training_center_detail_id: trainingCenterObject?.id ?? "",
      reporting_time: trainingCenterObject?.reporting_time ?? "",
      training_center_address:
        trainingCenterObject?.training_center_address ?? "",
      update: {
        ...prev.update,
        training_center_detail_id: trainingCenterObject?.id ?? "",
        reporting_time: trainingCenterObject?.reporting_time ?? "",
        training_center_address:
          trainingCenterObject?.training_center_address ?? "",
      },
    }));
  };

  const handleTrainingDetailsChanges = (e) => {
    const { name, value } = e.target;
    let val = name === "is_verified" ? e.target.checked : value;
    setTrainingDetails((prev) => ({
      ...prev,
      [name]: val,
      update: { ...prev.update, [name]: val },
    }));
  };

  // validation funtion
  const validateEmail = (email) => {
    const emailPattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    // const emailIsValid = emailPattern.test(email);
    const emailIsValid = emailPattern.test(email);
    return emailIsValid;
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\d{10}$/;
    const numberIsValid = phoneNumberPattern.test(phoneNumber);
    return numberIsValid;
  };

  function isFileExtensionValid(fileName) {
    const allowedExtensions = ["pdf", "jpeg", "jpg", "png", "heic", "svg"];
    const fileExtension = fileName.split(".").pop().toLowerCase();

    return allowedExtensions.includes(fileExtension);
  }

  const validatebasicDetail = async (e) => {
    let check = false;
    const checkvalue = e.target.checked;

    let opertorname;

    if (operatorName) {
      const findOpertorNameById = opertor?.find(
        (res) => res.id === operatorName
      );

      opertorname = findOpertorNameById?.operator_name;
    }

    if (basicDetailsCheck) {
      setBasicDetailsCheck(false);
      return;
    }

    const ignoreField = [
      "is_verified",
      "operator_full_name",
      "tnvsCheck",
      "nickName",
    ];

    Object.entries(basicDetails).forEach(([key, value]) => {
      if (
        driverType === "INDIVIDUAL" &&
        key === "operator_full_name" &&
        value === ""
      ) {
        check = true;
      } else if (
        value === "" &&
        !ignoreField.includes(key) &&
        key !== "nickName"
      ) {
        check = true;
      }
    });

    if (check) {
      toast.error("Fill All The Basic Details!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    if (driverType === "INDIVIDUAL" && !basicDetails?.tnvsCheck) {
      showErrorToast("Fill All The Basic Details!");
      return;
    }

    if (calculateAge(basicDetails?.dob ?? "") < 18) {
      showErrorToast("Age should be greater than 18");
      return;
    }

    if (driverType === "VIA_OPERATOR" && !operatorName) {
      showErrorToast("Please Select Operator Type");
      return;
    }

    const checkUniqness = await DriverDetailsValidationByApi({
      ...(id && { driver_id: id }),
      ...(basicDetails?.phoneNumber && {
        phone: `+63${basicDetails?.phoneNumber ?? ""}`,
      }),
      ...(basicDetails?.email && { email: basicDetails?.email }),
      ...(opertorname && { operator_name: opertorname }),
    });

    if (!checkUniqness) {
      return;
    }

    const checkEmail = validateEmail(basicDetails.email);

    const checkNumber = validatePhoneNumber(basicDetails.phoneNumber);

    if (!checkEmail) {
      toast.error("Invalid email address!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!checkNumber) {
      toast.error("Invalid number format!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!profilePicture.url) {
      toast.error("Please upload a profile picture!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!driverType) {
      toast.error("select driver type!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setBasicDetailsCheck(checkvalue);
  };

  const validateDocument = (e) => {
    if (documentsCheck) {
      setDocumentsCheck(false);
      return;
    }

    let check = false;

    const requiredDocuments = [
      document1,
      document2,
      document3,
      document4,
      document6,
    ];

    const valueToRemove = "LTFRB (Back Optional)";
    const updatedDocumentTitle = documentTitle.filter(
      (title) => title !== valueToRemove
    );

    requiredDocuments.forEach((res, index) => {
      if (
        (updatedDocumentTitle[index] === "Fit to work" &&
          age >= 60 &&
          !res?.url) ||
        (updatedDocumentTitle[index] !== "Fit to work" && !res?.url)
      ) {
        check = true;
      }
    });

    if (check) {
      toast.error("Plaese Upload all the documnets!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    setDocumentsCheck(e.target.checked);
  };

  const validateCarDetails = async (e) => {
    let check = false;
    const checkvalue = e.target.checked;
    if (carDetailsCheck) {
      setCarDetailsCheck(false);
      return;
    }

    if (
      carDocument1.url === "" ||
      carDocument2.url === "" ||
      carDocument3.url === ""
    ) {
      toast.error("Please Fill ALL Car Details!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    if (check) {
      toast.error("Upload ALL The Car Images!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }
    // ...(validateLtfrb && [""])
    Object.entries(carDetails).forEach((res) => {
      if (res[0] === "caseId" && validateLtfrb) {
        return;
      } else {
        if (res[1] === "") {
          check = true;
        }
      }
    });

    const checkCarType = carTypeCategory.some(
      (category) => category.value === carDetails?.carType
    );

    if (!checkCarType) {
      toast.error("Fill All The Car Details Field!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    if (check) {
      toast.error("Fill All The Car Details Field!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    if (
      ![
        carImage1.url,
        carImage2.url,
        carImage3.url,
        carImage4.url,
        carImage5.url,
      ].length > 0
    ) {
      toast.error("Should at atlest have one car image!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    if (!driverImageID.image) {
      toast.error("Upload The Driver Image!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    setCarDetailsCheck(checkvalue);
  };

  const validateBankDetails = (e) => {
    if (bankDetailsCheck) {
      setBankDetailsCheck(false);
    }

    let check = false;

    Object.entries(bankDetails).forEach((res) => {
      if (res[1] === "") {
        check = true;
      }
    });

    if (check) {
      toast.error("Fill All The Bank Details!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    setBankDetailsCheck(e.target.checked);
  };

  // Function to handle individual document uploads
  const handleDocumentUpload = (file, documentNumber) => {
    if (file) {
      const isValidFile = isFileExtensionValid(file.name);

      if (!isValidFile) {
        toast.error("Allowed file types: PDF, JPEG, JPG, PNG, HEIC, SVG.", {
          autoClose: 6000,
        });

        return;
      }
    } else {
      return;
    }

    const fileType = file?.type?.split("/")[1] ?? "";

    switch (documentNumber) {
      case 1:
        setDocument1({
          url: URL.createObjectURL(file),
          document_front_extension: fileType,
        });
        uploadFileToApi(file, "doc1");
        break;
      case 2:
        setDocument2({
          url: URL.createObjectURL(file),
          document_front_extension: fileType,
        });
        uploadFileToApi(file, "doc2");
        break;
      case 3:
        setDocument3({
          url: URL.createObjectURL(file),
          document_front_extension: fileType,
        });
        uploadFileToApi(file, "doc3");
        break;
      case 4:
        setDocument4({
          url: URL.createObjectURL(file),
          document_front_extension: fileType,
        });
        uploadFileToApi(file, "doc4");
        break;
      case 5:
        setDocument5({
          url: URL.createObjectURL(file),
          document_front_extension: fileType,
        });
        uploadFileToApi(file, "doc5");
        break;
      case 6:
        setDocument6({
          url: URL.createObjectURL(file),
          document_front_extension: fileType,
        });
        uploadFileToApi(file, "doc6");
        break;
      case 7:
        setDriverImageId((prev) => ({
          ...prev,
          image: URL.createObjectURL(file),
        }));
        uploadFileToApi(file, "driverProfile");
        break;
      default:
        break;
    }
  };

  const handleProfilePictureUpload = (file) => {
    if (file) {
      const isValidFile = isFileExtensionValid(file.name);

      if (!isValidFile) {
        toast.error("Allowed file types: PDF, JPEG, JPG, PNG, HEIC, SVG.", {
          autoClose: 6000,
        });

        return;
      }
    } else {
      return;
    }

    setProfilePicture((prev) => ({ ...prev, url: URL.createObjectURL(file) }));
    uploadFileToApi(file, "profilePicture");
  };

  const handleCarDocumentUpload = (file, documentNumber) => {
    if (file) {
      const isValidFile = isFileExtensionValid(file.name);

      if (!isValidFile) {
        toast.error("Allowed file types: PDF, JPEG, JPG, PNG, HEIC, SVG.", {
          autoClose: 6000,
        });

        return;
      }
    } else {
      return;
    }

    const filetype = file?.type?.split("/")[1];

    switch (documentNumber) {
      case 1:
        setCarDocument1({
          url: URL.createObjectURL(file),
          document_front_extension: filetype,
        });
        uploadFileToApi(file, "carDoc1");
        break;
      case 2:
        setCarDocument2({
          url: URL.createObjectURL(file),
          document_front_extension: filetype,
        });
        uploadFileToApi(file, "carDoc2");
        break;
      case 3:
        setCarDocument3({
          url: URL.createObjectURL(file),
          document_front_extension: filetype,
        });
        uploadFileToApi(file, "carDoc3");
        break;
      default:
        break;
    }
  };

  const isButtonDisable = !(
    basicDetailsCheck &&
    documentsCheck &&
    bankDetailsCheck &&
    carDetailsCheck &&
    tranningDetails.is_verified
  );

  const statusCheckBox = {
    basicDetailsCheck,
    documentsCheck,
    bankDetailsCheck,
    carDetailsCheck,
    tranningDetails: tranningDetails.is_verified,
  };

  const handleBankDocumentUpload = (file) => {
    if (file) {
      const isValidFile = isFileExtensionValid(file.name);

      if (!isValidFile) {
        toast.error("Allowed file types: PDF, JPEG, JPG, PNG, HEIC, SVG.", {
          autoClose: 6000,
        });

        return;
      }
    } else {
      return;
    }

    const fileType = getFileType(file);

    setBankDocumentFile((prev) => ({
      ...prev,
      url: URL.createObjectURL(file),
      document_front_extension: fileType,
    }));
    uploadFileToApi(file, "bankDoc");
  };

  // Create a function to handle the DELETE request
  const deleteDriver = async () => {
    const apiUrl = `/crm/driver/${id}/`;
    try {
      const response = await axiosInstance.delete(apiUrl);

      // Request was successful (status code 200)
      showSuccessToast("Driver deleted successfully");
      navigate(`/driver`);
    } catch (error) {
      // An error occurred during the request
      console.error("Error:", error);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "900px",
    height: "590px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    boxSizing: "border-box",
    p: 4,
  };

  // Create a function to generate the body for the basic details
  const generateBasicDetailsBody = () => {
    const filterEmptyProperties = (obj) => {
      return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value !== null && value !== "") {
          if (typeof value === "object" && !Array.isArray(value)) {
            // Recursively filter nested objects
            const nestedObj = filterEmptyProperties(value);
            if (Object.keys(nestedObj).length > 0) {
              acc[key] = nestedObj;
            }
          } else {
            acc[key] = value;
          }
        }
        return acc;
      }, {});
    };
    const car_id =
      idRef.current?.car_details_id ||
      DriverDetails.data[1]?.onboarding_step_data.car_details?.id;

    const onboardingData = {
      id: DriverDetails?.data[0]?.id,
      title_slug: "basic_details",
      status: basicDetailsCheck
        ? "APPROVED"
        : DriverDetails?.data[0]?.status ?? "PENDING",
      is_verified: basicDetailsCheck,
      onboarding_step_data: {
        full_name: basicDetails.name,
        gender: basicDetails.gender,
        profile_pic_id: profilePicture?.updateID ?? "",
        driver_type: driverType,
        operator_id: driverType === "INDIVIDUAL" ? null : operatorName, // Can only be applicable if driver_type is VIA_OPERATOR
        ...(driverType === "INDIVIDUAL" &&
          basicDetails?.operator_full_name && {
            operator_full_name: basicDetails?.operator_full_name,
          }),
        ...(driverType === "INDIVIDUAL" &&
          basicDetails?.tnvsCheck && {
            is_existing_tnvs: basicDetails?.tnvsCheck === "true",
          }),
        dob: basicDetails.dob,
        email: basicDetails.email,
        phone: "+63" + basicDetails.phoneNumber,
        tax_identification_number: basicDetails?.TIN,
        address: {
          ...(DriverDetails.data[0]?.onboarding_step_data.address
            ? {
                id: DriverDetails.data[0]?.onboarding_step_data.address.id,
                house_number: basicDetails.address1,
                barangay: basicDetails.address2,
                city: basicDetails.address3,
                postal_code: basicDetails.cityCode,
              }
            : {
                ...(idRef?.current?.address_id && {
                  id: idRef?.current?.address_id,
                }),
                house_number: basicDetails.address1,
                barangay: basicDetails.address2,
                city: basicDetails.address3,
                postal_code: basicDetails.cityCode,
              }),
        },
      },
    };

    // Filter out properties with empty or null values
    const filteredOnboardingData = filterEmptyProperties(onboardingData);

    return filteredOnboardingData;
  };
  // Create a function to generate the body for the documents
  const generateDocumentsBody = () => {
    const documents = [
      // Add other document objects as needed
      {
        id:
          driverDocument?.driving_license ||
          idRef.current?.driving_license_id ||
          "", // Use the corresponding state's id
        document_title_slug: "driving_license",
        document_front: documentRef?.current?.document1Id, // Use the corresponding state
        is_verified: documentsCheck,
      },
      {
        id:
          driverDocument?.nbi_certificate ||
          idRef.current?.nbi_certificate_id ||
          "", // Use the corresponding state's id
        document_title_slug: "nbi_certificate",
        document_front: documentRef?.current?.document2Id, // Use the corresponding state
        is_verified: documentsCheck,
      },
      {
        id:
          driverDocument?.covid_19_certificate ||
          idRef.current?.covid_19_certificate_id ||
          "", // Use the corresponding state's id
        document_title_slug: "covid_19_certificate",
        document_front: documentRef?.current?.document3Id, // Use the corresponding state
        is_verified: documentsCheck,
      },
      {
        id:
          driverDocument?.car_details_or ||
          idRef.current?.car_details_or_id ||
          "", // Use the corresponding state's id
        document_title_slug: "car_details_or",
        document_front: carnewDoc1, // Use the corresponding state
        is_verified: carDetailsCheck,
      },
      {
        id:
          driverDocument?.car_details_cr ||
          idRef.current?.car_details_cr_id ||
          "", // Use the corresponding state's id
        document_title_slug: "car_details_cr",
        document_front: carnewDoc2, // Use the corresponding state
        is_verified: carDetailsCheck,
      },
      {
        id:
          driverDocument?.car_details_insurance ||
          idRef.current?.car_details_insurance_id ||
          "", // Use the corresponding state's id
        document_title_slug: "car_details_insurance",
        document_front: carnewDoc3, // Use the corresponding state
        is_verified: carDetailsCheck,
      },
    ];

    if (age >= 60 && age !== "") {
      documents.push({
        id: driverDocument?.fit_to_work || idRef.current?.fit_to_work_id || "",
        document_title_slug: "fit_to_work",
        document_front: documentRef?.current?.document6Id,
        is_verified: documentsCheck,
      });
    }

    documents.push({
      id: driverDocument?.ltfrb_id || idRef.current?.ltfrb_id_id || "",
      document_title_slug: "ltfrb_id",
      document_front: documentRef?.current?.document4Id,
      document_back: documentRef?.current?.document5Id,
      is_verified: documentsCheck,
    });

    const filterData = filterEmptyPropertiesRecursively(documents);
    const checkingDoc = filterObjectsWithProperties(filterData);

    return checkingDoc;
  };

  // Fetch operators from the API and update the state
  const getOperator = async () => {
    let url = "/driver/operator/";
    try {
      await axiosInstance.get(url).then((res) => {
        setOpertaor(res?.data?.data ? [...res.data.data] : []);
      });
    } catch (error) {
      console.log("Error while fetching operator", error);
    }
  };

  // Create a function to generate the body for the bank details
  const generateBankDetailsBody = () => {
    const filterEmptyProperties = (obj) => {
      return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value !== null && value !== "") {
          acc[key] = value;
        }
        return acc;
      }, {});
    };

    // console.log(idRef);
    // console.log(
    //   DriverDetails.data[1]?.onboarding_step_data.bank_details?.id,
    //   "normal id",
    //   idRef.current.bank_details_id,
    //   bankDocumentFile
    // );

    const bankimg = {
      ...(banknewDoc && {
        id:
          idRef.current?.bank_document_ids[banknewDoc] ||
          bankDocumentFile.bankdocId,
        document_id: banknewDoc,
      }),
    };

    const bankDetailsData = {
      id:
        idRef.current?.bank_details_id ||
        DriverDetails.data[1]?.onboarding_step_data.bank_details?.id,
      account_number: bankDetails.accountNumber,
      account_holder_name: bankDetails.accountHolderName,
      bank_name: bankDetails.bankName,
      branch: bankDetails.branchBSB,
      documents: [],
    };

    if (Object.entries(bankimg).length > 0) {
      bankDetailsData["documents"] = [bankimg];
    }

    // Filter out properties with empty or null values
    const filteredBankDetailsData = filterEmptyProperties(bankDetailsData);
    return filteredBankDetailsData;
  };

  // Create a function to generate the body for the car details
  const generateCarDetailsBody = () => {
    const filterEmptyProperties = (obj) => {
      return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value !== null && value !== "") {
          acc[key] = value;
        }
        return acc;
      }, {});
    };

    // const carDetailsData = {
    //   ...(DriverDetails.data[1]?.onboarding_step_data.car_details
    //     ? {
    //         id: DriverDetails.data[1]?.onboarding_step_data.car_details?.id,
    //         registration_number: carDetails.carRegNumber,
    //         color: carDetails.carColor,
    //         model_number: carDetails.carModel,
    //         year_of_manufacture: carDetails.carYear,
    //         company: carDetails.carCompany,
    //         car_type: carDetails.carType,
    //       }
    //     : {
    //         ...(idRef.current?.car_details_id && {
    //           id: idRef.current?.car_details_id,
    //         }),
    //         registration_number: carDetails.carRegNumber,
    //         color: carDetails.carColor,
    //         model_number: carDetails.carModel,
    //         year_of_manufacture: carDetails.carYear,
    //         company: carDetails.carCompany,
    //         car_type: carDetails.carType,
    //       }),
    // };

    const id =
      idRef.current?.car_details_id ||
      DriverDetails.data[1]?.onboarding_step_data.car_details?.id;

    const carDetailsData = {
      ...(id && { id }),
      registration_number: carDetails.carRegNumber,
      color: carDetails.carColor,
      model_number: carDetails.carModel,
      year_of_manufacture: carDetails.carYear,
      company: carDetails.carCompany,
      car_type: carDetails.carType,
    };

    // Filter out properties with empty or null values
    const filteredCarDetailsData = filterEmptyProperties(carDetailsData);

    return filteredCarDetailsData;
  };

  const documentIdMap = {
    doc1: { state: newdoc1, setter: setNewDoc1 },
    doc2: { state: newdoc2, setter: setNewDoc2 },
    doc3: { state: newdoc3, setter: setNewDoc3 },
    doc4: { state: newdoc4, setter: setNewDoc4 },
    doc5: { state: newdoc5, setter: setNewDoc5 },
    doc6: { state: newdoc6, setter: setNewDoc6 },
    carDoc1: { state: carnewDoc1, setter: setCarNewDoc1 },
    carDoc2: { state: carnewDoc2, setter: setCarNewDoc2 },
    carDoc3: { state: carnewDoc3, setter: setCarNewDoc3 },
  };

  function clearDocumentById(idsArray) {
    idsArray.forEach((docId) => {
      const document = documentIdMap[docId];

      if (document && document.state !== null) {
        document.setter(null);
      }
    });
  }

  function uploadFileToApi(file, doc) {
    const apiUrl = "/utils/file/";
    const formData = new FormData();

    const options = {
      headers: {
        "Content-Type": "multipart/form-data", // This is important for form data
      },
    };
    formData.append("file", file);
    formData.append("folder", "public/");

    axiosInstance
      .post(apiUrl, formData, options)
      .then((data) => {
        // Handle the API response data here
        switch (doc) {
          case "doc1":
            documentRef.current = {
              ...documentRef.current,
              document1Id: data.data.data.id,
            };
            break;
          case "doc2":
            documentRef.current = {
              ...documentRef.current,
              document2Id: data.data.data.id,
            };
            break;
          case "doc3":
            documentRef.current = {
              ...documentRef.current,
              document3Id: data.data.data.id,
            };
            break;
          case "doc4":
            documentRef.current = {
              ...documentRef.current,
              document4Id: data.data.data.id,
            };
            break;
          case "doc5":
            documentRef.current = {
              ...documentRef.current,
              document5Id: data.data.data.id,
            };
            break;
          case "doc6":
            // setNewDoc6(data.data.data.id);
            documentRef.current = {
              ...documentRef.current,
              document6Id: data.data.data.id,
            };
            break;
          case "carDoc1":
            setCarNewDoc1(data.data.data.id);
            break;
          case "carDoc2":
            setCarNewDoc2(data.data.data.id);
            break;
          case "carDoc3":
            setCarNewDoc3(data.data.data.id);
            break;
          case "bankDoc":
            console.log(data.data.data.id);
            setBankNewDoc(data.data.data.id);
            break;
          case "driverProfile":
            setDriverImageId((prev) => ({
              ...prev,
              driverNewId: data.data.data.id,
            }));
            break;
          case "carImg1":
            setCarImage1((prev) => ({
              ...prev,
              old_id: prev.new_id,
              new_id: data.data.data.id,
            }));
            break;
          case "carImg2":
            setCarImage2((prev) => ({
              ...prev,
              old_id: prev.new_id,
              new_id: data.data.data.id,
            }));
            break;
          case "carImg3":
            setCarImage3((prev) => ({
              ...prev,
              old_id: prev.new_id,
              new_id: data.data.data.id,
            }));
            break;
          case "carImg4":
            setCarImage4((prev) => ({
              ...prev,
              old_id: prev.new_id,
              new_id: data.data.data.id,
            }));
            break;
          case "carImg5":
            setCarImage5((prev) => ({
              ...prev,
              old_id: prev.new_id,
              new_id: data.data.data.id,
            }));
            break;
          case "profilePicture":
            setProfilePicture((prev) => ({
              ...prev,
              updateID: data.data.data.id,
            }));
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  }

  const genrateHandleCarImages = () => {
    let data = [carImage1, carImage2, carImage3, carImage4, carImage5];

    const carImage = data.map((res) => {
      return {
        id: res?.id || idRef.current.car_image_ids[res?.old_id] || null,
        image_id: res.new_id,
      };
    });

    const filtercarImage = carImage.filter((res) => res.id || res.image_id);

    return filtercarImage;
  };

  const generateAppointmentBody = () => {
    const appointment_details = {
      ...(tranningDetails.update?.date &&
        !tranningDetails?.auto_appoint && {
          id: tranningDetails?.appintment_detail_id ?? "",
        }),
      ...(tranningDetails.update?.date &&
        !tranningDetails?.auto_appoint && {
          date: tranningDetails?.update?.date ?? "",
        }),
      ...(tranningDetails.update?.date &&
        !tranningDetails?.auto_appoint && {
          training_center_id: tranningDetails?.training_center_detail_id ?? "",
        }),
      ...(tranningDetails?.update?.slot_key &&
        !tranningDetails.auto_appoint && {
          slot_key: !tranningDetails.auto_appoint
            ? tranningDetails?.slot_key ?? ""
            : "",
        }),
      auto_appoint: tranningDetails?.auto_appoint
        ? tranningDetails?.auto_appoint
        : "",
    };

    const appointmentDate = {
      id: onboardingid?.visit_center,
      title_slug: "visit_center",
      is_verified: tranningDetails?.is_verified ?? false,
      status: tranningDetails.is_verified
        ? "APPROVED"
        : DriverDetails?.data[2]?.status ?? "PENDING",
      onboarding_step_data: {},
    };

    const appointment_detailsFilter =
      filterEmptyPropertiesRecursively(appointment_details);

    const checkingLength = Object.entries(appointment_detailsFilter);

    if (checkingLength.length === 1 || checkingLength.length === 3) {
      appointmentDate.onboarding_step_data["appointment_details"] =
        appointment_details;
    }

    const filterData = filterEmptyPropertiesRecursively(appointmentDate);
    return filterData;
  };

  const genrateApprovedBody = () => {
    let approvedData = {
      id: onboardingid?.final_approval,
      title_slug: "final_approval",
      is_verified: approved?.is_verified ?? "",
      status: approved?.is_verified ? "APPROVED" : "PENDING",
    };

    const filterData = filterEmptyPropertiesRecursively(approvedData);
    return filterData;
  };

  const handleApproved = () => {
    setApproved((prev) => ({ ...prev, is_verified: true }));
  };

  function validateTrainingCenter() {
    return false;
  }

  //Funtion to Validate driver
  const DriverDetailsValidationByApi = async (obj) => {
    if (!obj) return;

    const url = "/crm/driver/validation/";

    return axiosInstance
      .post(url, obj)
      .then((_) => {
        return true;
      })
      .catch((err) => {
        showErrorToast(err, {
          autoClose: 8000,
        });
        return false;
      });
  };

  const validateTranningDetails = (e) => {
    if (tranningDetails.is_verified) {
      setTrainingDetails((prev) => ({ ...prev, is_verified: false }));
      return;
    }

    setTrainingDetails((prev) => ({ ...prev, is_verified: true }));
  };

  // Funtion to Fetch userDate
  const fetchUserData = async () => {
    const url = `/crm/driver/onboarding-step-data/${id}/`;
    try {
      // Set loading to true while fetching data
      handleLoading("getDriverDetails", true);

      // Make the API request with the authorization headers
      const response = await axiosInstance.get(url);

      if (response.statusText !== "OK") {
        throw new Error("Network response was not ok");
      }

      const userData = await response.data;

      let stepIdObject = {};

      for (let i = 0; i < userData?.data?.length; i++) {
        const onboardingData = userData?.data[i];
        if (!stepIdObject[onboardingData?.title_slug]) {
          stepIdObject[onboardingData?.title_slug] = onboardingData?.id ?? "";
        }
      }
      setOnboardingId(stepIdObject);

      const basicDetailsData = userData.data[0]?.onboarding_step_data || {};
      const basicDetailStatus = userData.data[0].status;
      if (userData?.data[0]?.onboarding_step_data?.created_at) {
        let inputDate = userData?.data[0]?.onboarding_step_data?.created_at
          ? formatDate(
              userData?.data[0]?.onboarding_step_data?.created_at,
              timezone,
              dateformat
            )
          : "";

        setOnboardingDate(`${inputDate}`);
      }
      setDriverType(userData?.data[0]?.onboarding_step_data?.driver_type ?? "");
      let driverType =
        userData?.data[0]?.onboarding_step_data?.driver_type ?? "";
      setOpertaorName(
        driverType === "INDIVIDUAL"
          ? ""
          : userData?.data[0]?.onboarding_step_data?.operator_id ?? ""
      );
      setBasicDetailsCheck(userData.data[0]?.is_verified);
      setDocumentsCheck(userData.data[1]?.is_verified);
      setCarDetailsCheck(userData.data[1]?.is_verified);
      setBankDetailsCheck(userData.data[1]?.is_verified);
      setBasicDetails({
        ...basicDetails,
        id: userData.data[0]?.id || "",
        name: basicDetailsData.full_name || "",
        email: basicDetailsData.email || "",
        gender: basicDetailsData.gender || "",
        dob: basicDetailsData.dob || "",
        nickName: basicDetailsData?.nick_name || "",
        address1: (basicDetailsData.address || {}).house_number || "",
        address2: (basicDetailsData.address || {}).barangay || "",
        address3: (basicDetailsData.address || {}).city || "",
        cityCode: (basicDetailsData.address || {}).postal_code || "",
        phoneNumber: basicDetailsData?.phone?.slice(3) || "",
        accountStatus: basicDetailsData.account_status,
        operator_full_name: basicDetailsData?.operator_name,
        TIN: basicDetailsData.tax_identification_number,
        tnvsCheck:
          basicDetailsData?.is_existing_tnvs != null
            ? basicDetailsData.is_existing_tnvs
              ? "true"
              : "false"
            : "",
      });

      if (typeof basicDetailsData?.peekup_points === "number") {
        setPeekupPoint(basicDetailsData?.peekup_points);
      }

      setValidateLtfrb(
        basicDetailsData?.is_existing_tnvs != null
          ? basicDetailsData.is_existing_tnvs === false
            ? true
            : null
          : null
      );

      setDownloadCertificateCheck({
        tnvsCheck: basicDetailsData?.is_existing_tnvs ?? null,
        opertorType: userData?.data[0]?.onboarding_step_data?.driver_type ?? "",
        download_certificate: userData?.data[0]?.download_certificate || true,
      });

      // Iterate through the documents array and set states based on 'document_title_slug'
      const documentStatus = userData.data[1].status;
      let documentObject = {};

      userData.data[1].onboarding_step_data.documents.forEach((document) => {
        const {
          document_title_slug,
          document_front_url,
          document_front_extension,
          document_back_extension,
          document_back_url,
          id,
        } = document;
        if (!documentObject[document_title_slug]) {
          documentObject[document_title_slug] = id;
        }
        switch (document_title_slug) {
          case "driving_license":
            setDocument1({
              url: document_front_url,
              document_front_extension: document_front_extension,
            });
            break;
          case "nbi_certificate":
            setDocument2({
              url: document_front_url,
              document_front_extension: document_front_extension,
            });
            break;
          case "covid_19_certificate":
            setDocument3({
              url: document_front_url,
              document_front_extension: document_front_extension,
            });
            break;
          case "ltfrb_id":
            setDocument4({
              url: document_front_url,
              document_front_extension: document_front_extension,
            });
            setDocument5({
              url: document_back_url,
              document_front_extension: document_back_extension,
            });
            break;
          case "fit_to_work":
            setDocument6({
              url: document_front_url,
              document_front_extension: document_front_extension,
            });
            break;
          case "car_details_cr":
            setCarDocument2({
              url: document_front_url,
              document_front_extension: document_front_extension,
            });
            break;
          case "car_details_or":
            setCarDocument1({
              url: document_front_url,
              document_front_extension: document_front_extension,
            });
            break;
          case "car_details_insurance":
            setCarDocument3({
              url: document_front_url,
              document_front_extension: document_front_extension,
            });
            break;
          default:
            console.log(
              `Unhandled document_title_slug: ${document_title_slug}`
            );
            // You can log a message or take other actions for unhandled cases
            break;
        }
      });

      setDriverDocument(documentObject);

      setProfilePicture((prev) => ({
        ...prev,
        url: userData.data[0]?.onboarding_step_data.profile_pic_url,
      }));
      const carDetailsData =
        userData.data[1]?.onboarding_step_data?.car_details || {};
      setCarDetails({
        ...carDetails,
        carRegNumber: carDetailsData.registration_number || "",
        carColor: carDetailsData.color || "",
        carModel: carDetailsData.model_number || "",
        carYear: carDetailsData.year_of_manufacture || "",
        carCompany: carDetailsData.company || "",
        carType: carDetailsData.car_type || "",
        caseId: userData.data[1]?.onboarding_step_data?.case_id || "",
      });
      const bankDetailsData =
        userData.data[1]?.onboarding_step_data?.bank_details || {};
      setBankDetails({
        ...bankDetails,
        bankName: bankDetailsData.bank_name || "",
        branchBSB: bankDetailsData.branch || "",
        accountNumber: bankDetailsData.account_number || "",
        accountHolderName: bankDetailsData.account_holder_name || "",
      });

      if (
        userData.data[1]?.onboarding_step_data?.bank_details?.documents &&
        userData.data[1]?.onboarding_step_data?.bank_details?.documents
          ?.length !== 0
      ) {
        setBankDocumentFile((prev) => ({
          ...prev,
          url: userData.data[1]?.onboarding_step_data?.bank_details
            ?.documents[0]?.url,
          document_front_extension:
            userData.data[1]?.onboarding_step_data?.bank_details?.documents[0]
              ?.extension,
          bankdocId:
            userData.data[1]?.onboarding_step_data?.bank_details?.documents[0]
              ?.id,
        }));
      } else {
        setBankDocumentFile((prev) => ({
          ...prev,
          url:
            userData.data[1]?.onboarding_step_data?.bank_details
              ?.document_url ?? "",
        }));
      }

      setDriverDetails(userData);

      setDriveStats({
        total_cancelled_rides: userData?.data[0]?.total_cancelled_rides ?? 0,
        total_earnings: userData?.data[0]?.total_earnings ?? 0,
        total_distance_travelled_in_km:
          userData?.data[0]?.total_distance_travelled_in_km ?? 0,
        total_rides: userData?.data[0]?.total_rides ?? 0,
      });

      userData.data[1]?.onboarding_step_data?.car_images?.forEach(
        (res, index) => {
          switch (index) {
            case 0:
              setCarImage1((prev) => ({
                ...prev,
                url: res.image_url,
                id: res.id,
                image_id: res.image_id,
              }));
              break;
            case 1:
              setCarImage2((prev) => ({
                ...prev,
                url: res.image_url,
                id: res.id,
                image_id: res.image_id,
              }));
              break;
            case 2:
              setCarImage3((prev) => ({
                ...prev,
                url: res.image_url,
                id: res.id,
                image_id: res.image_id,
              }));
              break;
            case 3:
              setCarImage4((prev) => ({
                ...prev,
                url: res.image_url,
                id: res.id,
                image_id: res.image_id,
              }));
              break;
            case 4:
              setCarImage5((prev) => ({
                ...prev,
                url: res.image_url,
                id: res.id,
                image_id: res.image_id,
              }));
              break;
            default:
              break;
          }
        }
      );

      const trainingStatus = userData.data[2].status;
      let tranining = userData.data[2];
      const appointmentDetailsObj =
        tranining?.onboarding_step_data?.appointment_details ?? {};
      setTrainingDetails((prev) => ({
        ...prev,
        id: tranining?.id ?? "",
        is_verified: tranining?.is_verified ?? false,
        appintment_detail_id:
          tranining?.onboarding_step_data?.appointment_details?.id ?? "",
        training_center_detail_id:
          tranining?.onboarding_step_data?.appointment_details
            ?.training_center_detials?.id ?? "",
        date: tranining?.onboarding_step_data?.appointment_details?.date ?? "",
        reporting_time:
          tranining?.onboarding_step_data?.appointment_details
            ?.training_center_detials?.reporting_time ?? "",
        slot_key: appointmentDetailsObj?.slot_key,
      }));

      getTrainingCenter().then((res) => {
        handleTrainingCenterAndSlotOptions(
          tranining?.onboarding_step_data?.appointment_details
            ?.training_center_detials?.id,
          res
        );
      });

      setDriverImageId((prev) => ({
        ...prev,
        image: userData.data[1]?.onboarding_step_data?.driver_image_url ?? "",
      }));

      setApproved({
        id: userData.data[3]?.id ?? "",
        is_verified: userData.data[3]?.is_verified ?? false,
      });

      setIsDriverVerified(userData.data[3]?.is_verified ?? false);
      setApiCallMade(userData.data[3]?.is_verified ?? false);

      const approvalStatus = userData.data[3]?.status;

      setStatus({
        riderStatus: basicDetailStatus,
        documentStatus: documentStatus,
        tranningStatus: trainingStatus,
        approvalStatus: approvalStatus,
      });
    } catch (error) {
      if (
        error?.response?.data?.message === "Driver not exists with given id"
      ) {
        navigate("/not-found");
      }
      console.error("Fetch error:", error);
      showErrorToast(error);
    } finally {
      // Set loading to false when data fetching is complete (success or error)
      handleLoading("getDriverDetails", false);
    }
  };

  const handleSubmit = async (type = "") => {
    if (basicDetails?.dob && age < 18) {
      showErrorToast("Age should be greater than 18");
      return;
    }

    let opertorname;

    if (operatorName) {
      const findOpertorNameById = opertor?.find(
        (res) => res.id === operatorName
      );
      opertorname = findOpertorNameById?.operator_name;
    }

    const checkUniqness = await DriverDetailsValidationByApi({
      ...(id && { driver_id: id }),
      ...(basicDetails?.phoneNumber && {
        phone: `+63${basicDetails?.phoneNumber ?? ""}`,
      }),
      ...(basicDetails?.email && { email: basicDetails?.email }),
      ...(carDetails?.caseId && { case_id: carDetails?.caseId }),
      ...(opertorname && { operator_name: opertorname }),
    });

    if (!checkUniqness) {
      return;
    }

    if (validateTrainingCenter()) return;
    let documentsIsVerify =
      documentsCheck && bankDetailsCheck && carDetailsCheck;
    const dataToUpdate = {
      body: [
        generateBasicDetailsBody(), // Use the generated body for basic details
        {
          id: DriverDetails.data[1]?.id,
          is_verified: documentsIsVerify,
          status: documentsIsVerify
            ? "APPROVED"
            : DriverDetails?.data[1]?.status ?? "PENDING",
          onboarding_step_data: {
            documents: generateDocumentsBody() ?? "", // Use the generated body for documents
            bank_details: generateBankDetailsBody() ?? "", // Use the generated body for bank details
            driver_image_id: driverImageID?.driverNewId,
            car_details: generateCarDetailsBody() ?? "", // Use the generated body for car details
            car_images: genrateHandleCarImages() ?? "",
            case_id: carDetails?.caseId ?? "",
          },
        },
        generateAppointmentBody() ?? "",
        genrateApprovedBody() ?? "",
      ],
    };

    const filterData = filterEmptyPropertiesRecursively(dataToUpdate);

    // console.log(JSON.stringify(filterData), "submit object");

    await axiosInstance
      .patch(`/crm/driver/update-onboarding-step-data/${id}/`, filterData)
      .then((res) => {
        if (profilePicture.updateID) {
          setProfilePicture((prev) => ({ ...prev, updateID: "" }));
        }
        if (carnewDoc1) {
          setCarNewDoc1("");
        }
        if (carnewDoc2) {
          setCarNewDoc2("");
        }
        if (banknewDoc) {
          setBankNewDoc("");
        }

        if (carImage1.new_id) {
          setCarImage1((prev) => ({ ...prev, new_id: "" }));
        }
        if (carImage2.new_id) {
          setCarImage2((prev) => ({ ...prev, new_id: "" }));
        }

        if (carImage3.new_id) {
          setCarImage3((prev) => ({ ...prev, new_id: "" }));
        }

        if (carImage4.new_id) {
          setCarImage4((prev) => ({ ...prev, new_id: "" }));
        }

        if (carImage5.new_id) {
          setCarImage5((prev) => ({ ...prev, new_id: "" }));
        }

        if (!apiCallMade) {
          setApiCallMade(true);
        }
        navigate("/driver");
        toast.success("Driver information updated successfully!");
      })
      .catch((error) => {
        showErrorToast(error);

        if (status.approvalStatus !== "APPROVED") {
          setApiCallMade(false);
          setApproved((prev) => ({ ...prev, is_verified: false }));
        }

        console.log(error);
        console.log("Error while Fetching Driver Details", error);
      });
  };

  useEffect(() => {
    if (approved.is_verified && !apiCallMade) {
      handleSubmit("approved");
    }
  }, [approved.is_verified, apiCallMade]);

  useEffect(() => {
    const getBankOptions = async () => {
      try {
        const response = await axios.get(`${config}/driver/bank/`);
        setBankNameOptions(response?.data?.data ?? []);
      } catch (error) {
        showErrorToast(error);
      }
    };

    getBankOptions();
    fetchUserData();
    getOperator();
  }, []);
  //file, "driverProfile"
  return (
    <>
      {loading?.getDriverDetails ? (
        "Loading..."
      ) : (
        <Box sx={{ m: "1.5rem 4rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ m: "15px 10px" }}>
                <ArrowBackIcon
                  onClick={() => navigate(-1)}
                  sx={{ cursor: "pointer" }}
                />
              </Box>
              <Box>
                <Typography>{basicDetails.name}</Typography>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "small", color: "#6F747E" }}
                >
                  Onboarding On {`${onboardingDate}`}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: "flex", gap: "15px" }}>
                {downloadCertificateCheck?.opertorType === "INDIVIDUAL" &&
                  typeof downloadCertificateCheck?.tnvsCheck !== "undefined" &&
                  !downloadCertificateCheck?.tnvsCheck && (
                    <Box>
                      <Button
                        variant="contained"
                        disabled={
                          downloadCertificateCheck?.download_certificate
                        }
                        sx={{
                          backgroundColor: "#fff",
                          "&:hover": { backgroundColor: "#fff" },
                          color: "#000",
                          m: "20px 0 0 0",
                        }}
                        onClick={handleSubmit}
                        type="submit"
                      >
                        Download Certificate
                      </Button>
                    </Box>
                  )}
                <Box>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "black", m: "20px 0 0 0" }}
                    onClick={handleSubmit}
                    type="submit"
                  >
                    Update
                  </Button>
                </Box>
                <Box sx={{ m: "20px 10px", boxSizing: "border-box" }}>
                  <div>
                    <IconButton onClick={handleClick}>
                      <MoreVertIcon sx={{ color: "black" }} />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                      {options.map((option) => (
                        <MenuItem
                          key={option}
                          onClick={(e) => {
                            // important plase provide event else it will through error
                            handleMenuClick(e);
                            handleClose();
                          }}
                        >
                          {option === "Block Driver"
                            ? basicDetails.accountStatus === "BLOCKED"
                              ? "Unblock Driver"
                              : "Block Driver"
                            : option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </Box>
              </Box>

              <Box sx={{ m: "15px 0" }}></Box>
            </Box>
          </Box>

          {isDriverVerified && (
            <Box sx={{ flexGrow: 1, m: "1rem 0" }}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}
              >
                <Grid item xs={2.2}>
                  <Card sx={{ maxWidth: 220 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          align="center"
                        >
                          {driveStats?.total_rides ?? 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          align="center"
                        >
                          Total Trips
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={2.2}>
                  <Card sx={{ maxWidth: 220 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          align="center"
                        >
                          ₱ {driveStats?.total_earnings ?? 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          align="center"
                        >
                          Total Earnings
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={2.5}>
                  <Card sx={{ maxWidth: 220 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          align="center"
                        >
                          {driveStats?.total_distance_travelled_in_km ?? 0} KM
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          align="center"
                        >
                          Total Distance
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={2.2}>
                  <Card sx={{ maxWidth: 220 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          align="center"
                        >
                          {driveStats?.total_cancelled_rides ?? 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          align="center"
                        >
                          Total Cancellations
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={2.2}>
                  <Card sx={{ maxWidth: 220 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          align="center"
                        >
                          {peekupPoints ?? 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          align="center"
                        >
                          Peekup Points
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box sx={{ flexGrow: 1, m: "1rem 0", backgroundColor: "white" }}>
            <DriverBasicDetails
              id={id}
              idRef={idRef}
              basicDetails={basicDetails}
              statusCheckBox={statusCheckBox}
              handleCheckboxChange={handleCheckboxChange}
              generateBasicDetailsBody={generateBasicDetailsBody}
              profilePicture={profilePicture}
              operator={opertor}
              handleProfilePictureUpload={(file) =>
                handleProfilePictureUpload(file)
              }
              setBasicDetails={setBasicDetails}
              basicDetailsCheck={basicDetailsCheck}
              onChange={validatebasicDetail}
              setDriverType={setDriverType}
              setOpertaorName={setOpertaorName}
              operatorName={operatorName}
              driverType={driverType}
              verificationCheckLabel={"Driver Basic Details"}
              boxHeaderLabel={"Driver basic Details"}
              profilePictureLabel={"UPLOAD PHOTO"}
              saveButton={true}
              setValidateLtfrb={setValidateLtfrb}
              DriverDetailsValidationByApi={DriverDetailsValidationByApi}
              isDisabledCheckbox={approved?.is_verified}
            />
            <br />
            <Divider />
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              m="0.5rem 1.5rem"
            >
              Documents
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                m: "1.5rem 1.5rem",
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                gap: "50px",
              }}
            >
              {[
                document1,
                document2,
                document3,
                document4,
                document5,
                document6,
              ].map((file, index) => (
                <React.Fragment key={index}>
                  {index === 5 && age >= 60 && (
                    <div>
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "600",
                          lineHeight: "normal",
                          marginBottom: "12px",
                        }}
                      >
                        {`${index + 1}. ${documentTitle[index] ?? ""}`}
                      </Typography>
                      <GenericFileUpload
                        file={file}
                        inputId={`document-upload-input-${index + 1}`}
                        handleDocumentUpload={handleDocumentUpload}
                        index={index}
                        key={`document-upload-input-${index + 1}`}
                      />
                    </div>
                  )}
                  {/* Add more conditions as needed for other indices */}
                  {index !== 5 && (
                    <div>
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "600",
                          lineHeight: "normal",
                          marginBottom: "12px",
                        }}
                      >
                        {`${index + 1}. ${documentTitle[index] ?? ""}`}
                      </Typography>
                      <GenericFileUpload
                        file={file}
                        inputId={`document-upload-input-${index + 1}`}
                        handleDocumentUpload={handleDocumentUpload}
                        index={index}
                        key={`document-upload-input-${index + 1}`}
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </Box>
            <Box
              sx={{
                margin: "0 1.5rem",
              }}
            >
              <CommonSaveButton
                clearDocumentById={clearDocumentById}
                updateIdName="documents"
                idRef={idRef}
                clearDocument={true}
                loading={loading}
                title={"documents"}
                handleLoading={handleLoading}
                body={() => {
                  return {
                    id: DriverDetails.data[1]?.id,
                    title_slug: "verify_documents",
                    onboarding_step_data: {
                      documents: generateDocumentsBody() ?? "", // Use the generated body for documents
                    },
                  };
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                backgroundColor: documentsCheck
                  ? "rgba(0, 157, 15, 0.20)"
                  : "#EAEAEA",
              }}
            >
              <Checkbox
                checked={documentsCheck}
                color="success"
                disabled={approved?.is_verified}
                onChange={(e) => {
                  const obj = {
                    ...statusCheckBox,
                    documentsCheck: e.target.checked,
                  };

                  if (handleCheckboxChange(obj, "documentsCheck")) return;

                  validateDocument(e);
                }}
                sx={{ color: "#009D0F" }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                mt="0.5rem"
                sx={{
                  fontSize: "22px",
                  fontStyle: "normal",
                  fontFamily: "Proxima Nova",
                  lineHeight: "normal",
                }}
              >
                Documents verified
              </Typography>
            </Box>
            <br />
            <Divider />
            <FlexBetween sx={{ m: "1.5rem 1.5rem" }}>
              <Typography gutterBottom variant="h6" component="div" mt="0.5rem">
                Car Details
              </Typography>
            </FlexBetween>
            <Box
              sx={{
                flexGrow: 1,
                m: "1.5rem 1.5rem",
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                gap: "50px",
              }}
            >
              {[carDocument1, carDocument2, carDocument3].map((file, index) => (
                <div key={`car-document-upload-input-${index + 1}`}>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: "600",
                      lineHeight: "normal",
                      marginBottom: "12px",
                    }}
                  >
                    {`${index + 1}. ${carTitle[index]}`}
                  </Typography>
                  <GenericFileUpload
                    file={file}
                    inputId={`car-document-upload-input-${index + 1}`}
                    handleDocumentUpload={handleCarDocumentUpload}
                    index={index}
                  />
                </div>
              ))}
            </Box>
            <Box sx={{ flexGrow: 1, m: "1.5rem 1.5rem" }}>
              <Typography
                sx={{
                  fontSize: "22px",
                  fontStyle: "normal",
                  fontFamily: "Proxima Nova",
                  lineHeight: "normal",
                }}
              >
                3. Car image
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                  width: "100%",
                  gap: "25px",
                }}
              >
                {[carImage1, carImage2, carImage3, carImage4, carImage5].map(
                  (file, index) => (
                    <>
                      <label
                        htmlFor={`carimg-document-upload-input-${index + 1}`}
                      >
                        <div
                          className="form"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "2px dashed lightgray",
                            height: "250px",
                            width: "470px",
                            cursor: "pointer",
                            borderRadius: "5px",
                            backgroundColor: "#0000000A",
                          }}
                        >
                          {file?.url ? (
                            <img
                              alt="Profile Pic"
                              width={250}
                              height={150}
                              src={file.url}
                            />
                          ) : (
                            ""
                          )}
                          {file?.url ? (
                            <Button
                              variant="text"
                              sx={{
                                color: "#009D0F",
                                fontFamily: "Proxima Nova",
                                fontSize: "24px",
                                textTransform: "none",
                              }}
                              startIcon={<FileUploadIcon />}
                              onClick={() =>
                                document
                                  .getElementById(
                                    `carimg-document-upload-input-${index + 1}`
                                  )
                                  .click()
                              }
                            >
                              Change
                            </Button>
                          ) : (
                            <Button
                              variant="text"
                              sx={{
                                color: "black",
                                fontFamily: "Proxima Nova",
                                fontSize: "24px",
                                textTransform: "none",
                              }}
                              startIcon={<FileUploadIcon />}
                              onClick={() =>
                                document
                                  .getElementById(
                                    `carimg-document-upload-input-${index + 1}`
                                  )
                                  .click()
                              }
                            >
                              Upload
                            </Button>
                          )}

                          <Typography
                            gutterBottom
                            variant="p"
                            component="span"
                            fontSize="small"
                            sx={{ mt: "5px" }}
                          >
                            Maximum 5 MB. Upload .jpeg, .png, or .pdf files.
                          </Typography>
                        </div>
                      </label>
                      <FileUploadInput
                        id={`carimg-document-upload-input-${index + 1}`}
                        onFileChange={(file) =>
                          handleCarImageChanges(file, index + 1)
                        }
                      />
                    </>
                  )
                )}
              </Box>
            </Box>
            <Box sx={{ width: "80%", m: "1.5rem 1.5rem" }}>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label={"Registration Number"}
                  value={carDetails.carRegNumber}
                  onChange={(e) => {
                    setCarDetails({
                      ...carDetails,
                      carRegNumber: e.target.value,
                    });
                  }}
                  sx={{ mb: 4, width: "50%" }}
                />
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label={"Color of the Car"}
                  value={carDetails.carColor}
                  onChange={(e) => {
                    setCarDetails({ ...carDetails, carColor: e.target.value });
                  }}
                  fullWidth
                />
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label={"Model Number"}
                  value={carDetails.carModel}
                  onChange={(e) => {
                    setCarDetails({ ...carDetails, carModel: e.target.value });
                  }}
                  sx={{ mb: 4, width: "50%" }}
                />
                <TextField
                  select
                  label={"Make/Year of Manufacture"}
                  value={carDetails.carYear}
                  onChange={(e) => {
                    setCarDetails({ ...carDetails, carYear: e.target.value });
                  }}
                  defaultValue="2023"
                  fullWidth
                >
                  {Array.from(
                    { length: currentYear - 1950 + 1 },
                    (_, index) => (
                      <MenuItem key={index} value={currentYear - index}>
                        {currentYear - index}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Stack>
              <Stack spacing={2} direction="row">
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label={"Company"}
                  value={carDetails.carCompany}
                  onChange={(e) => {
                    setCarDetails({
                      ...carDetails,
                      carCompany: e.target.value,
                    });
                  }}
                  sx={{ mb: 4, width: "50%" }}
                />
                <TextField
                  select
                  variant="outlined"
                  color="secondary"
                  label={"Car Type"}
                  value={carDetails.carType}
                  fullWidth
                  disabled={
                    approved?.is_verified &&
                    carTypeCategory.some(
                      (category) => category.value === carDetails?.carType
                    )
                  }
                  onChange={(e) => {
                    setCarDetails({
                      ...carDetails,
                      carType: e.target.value,
                    });
                  }}
                  sx={{ mb: 4 }}
                >
                  {carTypeCategory.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              {(!validateLtfrb || approved?.is_verified) && (
                <Stack spacing={2} direction="row" mt="20px">
                  <TextField
                    type="text"
                    variant="outlined"
                    color="secondary"
                    label={"Ltfrb Id"}
                    value={carDetails.caseId}
                    sx={{
                      width: "33%",
                    }}
                    onBlur={async (e) => {
                      let operatorName;

                      if (operatorName) {
                        const findOperatorNameById = opertor?.find(
                          (res) => res.id === operatorName
                        );
                        operatorName = findOperatorNameById?.operator_name;
                      }

                      if (e.target.value?.length > 0) {
                        await DriverDetailsValidationByApi({
                          case_id: e.target.value,
                          ...(id && { driver_id: id }),
                          ...(operatorName && { operator_name: operatorName }),
                        });
                      }
                    }}
                    onChange={(e) => {
                      setCarDetails({
                        ...carDetails,
                        caseId: e.target.value,
                      });
                    }}
                  />
                </Stack>
              )}
            </Box>
            <Box sx={{ flexGrow: 1, m: "1.5rem 1.5rem" }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  lineHeight: "normal",
                  marginBottom: "12px",
                }}
              >
                4. Driver image
              </Typography>

              <Box
                sx={{ flexGrow: 1, m: "1.5rem 1.5rem", position: "relative" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    className="form"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "2px dashed lightgray",
                      height: "250px",
                      width: "470px",
                      cursor: "pointer",
                      borderRadius: "5px",
                      backgroundColor: "#0000000A",
                    }}
                  >
                    {driverImageID.image ? (
                      <img
                        alt="Profile Pic"
                        width={250}
                        height={150}
                        src={driverImageID.image ?? ""}
                      />
                    ) : (
                      ""
                    )}
                    {driverImageID.image ? (
                      <Button
                        variant="text"
                        sx={{
                          color: "#009D0F",
                          fontFamily: "Proxima Nova",
                          fontSize: "24px",
                          textTransform: "none",
                          position: "absolute",
                          bottom: "0",
                          left: "500px",
                        }}
                        startIcon={<FileUploadIcon />}
                        onClick={() =>
                          document
                            .getElementById(
                              `driverid-document-upload-input-${0}`
                            )
                            .click()
                        }
                      >
                        Change
                      </Button>
                    ) : (
                      <Button
                        variant="text"
                        sx={{
                          color: "black",
                          fontFamily: "Proxima Nova",
                          fontSize: "24px",
                          textTransform: "none",
                        }}
                        startIcon={<FileUploadIcon />}
                        onClick={() =>
                          document
                            .getElementById(
                              `driverid-document-upload-input-${0}`
                            )
                            .click()
                        }
                      >
                        Upload
                      </Button>
                    )}

                    <Typography
                      gutterBottom
                      variant="p"
                      component="span"
                      fontSize="small"
                      sx={{ mt: "5px" }}
                    >
                      Maximum 5 MB. Upload .jpeg, .png, or .pdf files.
                    </Typography>
                  </div>
                  <FileUploadInput
                    id={`driverid-document-upload-input-${0}`}
                    onFileChange={(file) => handleDocumentUpload(file, 7)}
                  />

                  <Box
                    sx={{
                      width: "calc(100% - 470px)",
                    }}
                  >
                    <CommonSaveButton
                      clearDocumentById={clearDocumentById}
                      clearDocument={true}
                      loading={loading}
                      idRef={idRef}
                      title={"carDetails"}
                      handleLoading={handleLoading}
                      body={() => {
                        return {
                          id: DriverDetails.data[1]?.id,
                          onboarding_step_data: {
                            documents: generateDocumentsBody(),
                            // Use the generated body for bank details
                            driver_image_id: driverImageID?.driverNewId ?? "",
                            car_details: generateCarDetailsBody() ?? "", // Use the generated body for car details
                            car_images: genrateHandleCarImages() ?? "",
                            case_id: carDetails?.caseId ?? "",
                          },
                        };
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                backgroundColor: carDetailsCheck
                  ? "rgba(0, 157, 15, 0.20)"
                  : "#EAEAEA",
              }}
            >
              <Checkbox
                checked={carDetailsCheck}
                disabled={approved?.is_verified}
                color="success"
                onChange={(e) => {
                  const obj = {
                    ...statusCheckBox,
                    carDetailsCheck: e.target.checked,
                  };

                  if (handleCheckboxChange(obj, "carDetailsCheck")) return;

                  validateCarDetails(e);
                }}
                sx={{ color: "#009D0F" }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                mt="0.5rem"
                style={{
                  fontSize: "22px",
                  fontStyle: "normal",
                  fontFamily: "Proxima Nova",
                  lineHeight: "normal",
                }}
              >
                Car details verified
              </Typography>
            </Box>
            <br />
            <Divider />
            <FlexBetween sx={{ m: "1.5rem 1.5rem" }}>
              <Typography gutterBottom variant="h6" component="div" mt="0.5rem">
                Bank Details
              </Typography>
            </FlexBetween>
            <Box sx={{ m: "1.5rem 1.5rem" }}>
              <GenericFileUpload
                file={bankDocumentFile}
                inputId={`bank-document-upload-input`}
                handleDocumentUpload={handleBankDocumentUpload}
              />

              <FileUploadInput
                id=""
                onFileChange={(file) => handleBankDocumentUpload(file)}
              />
            </Box>
            <Box sx={{ width: "80%", m: "1.5rem 1.5rem" }}>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <CommonAutoComplete
                  options={bankNameOptions}
                  bankDetails={bankDetails}
                  setBankDetails={setBankDetails}
                />

                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label="Branch BSB"
                  value={bankDetails.branchBSB}
                  onChange={(e) => {
                    setBankDetails({
                      ...bankDetails,
                      branchBSB: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="number"
                  variant="outlined"
                  color="secondary"
                  label={"Account Number"}
                  value={bankDetails.accountNumber}
                  onChange={(e) => {
                    setBankDetails({
                      ...bankDetails,
                      accountNumber: e.target.value,
                    });
                  }}
                  fullWidth
                />
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label={"Account Holder Name"}
                  value={bankDetails.accountHolderName}
                  onChange={(e) => {
                    setBankDetails({
                      ...bankDetails,
                      accountHolderName: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Stack>
            </Box>

            <Box sx={{ margin: "0px 1.5rem" }}>
              <CommonSaveButton
                loading={loading}
                handleLoading={handleLoading}
                title={"bankDetails"}
                idRef={idRef}
                body={() => {
                  return {
                    id: DriverDetails.data[1]?.id,
                    title_slug: "verify_documents",
                    onboarding_step_data: {
                      bank_details: generateBankDetailsBody() ?? "", // Use the generated body for bank details
                    },
                  };
                }}
              />
            </Box>

            <Box
              sx={{
                backgroundColor: bankDetailsCheck
                  ? "rgba(0, 157, 15, 0.20)"
                  : "#EAEAEA",
                display: "flex",
              }}
            >
              <Checkbox
                checked={bankDetailsCheck}
                color="success"
                disabled={approved?.is_verified}
                onChange={(e) => {
                  const obj = {
                    ...statusCheckBox,
                    bankDetailsCheck: e.target.checked,
                  };

                  if (handleCheckboxChange(obj, "bankDetailsCheck")) return;

                  validateBankDetails(e);
                }}
                sx={{ color: "#009D0F" }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                mt="0.5rem"
                sx={{
                  fontSize: "22px",
                  fontStyle: "normal",
                  fontFamily: "Proxima Nova",
                  lineHeight: "normal",
                }}
              >
                Bank details verified
              </Typography>
            </Box>
            <br />
            <Divider />
            <DriverTrainingDetails
              tranningDetails={tranningDetails}
              handleTrainingDetailsChanges={handleTrainingDetailsChanges}
              tranningCenter={tranningCenter}
              handleTraningCenter={handleTraningCenter}
              setTrainingDetails={setTrainingDetails}
              validateTranningDetails={validateTranningDetails}
              handleCheckboxChange={handleCheckboxChange}
              statusCheckBox={statusCheckBox}
              slotOptions={slotOptions}
              handleSlotOptions={handleSlotOptions}
              isDisabledCheckbox={approved?.is_verified}
            />
            <br />
            <Divider />
            <Box
              sx={{
                display: "flex",
                width: "100%",
                boxSizing: "border-box",
                padding: approved.is_verified
                  ? "18px 0"
                  : "20px 24px 20px 200px",
                marginTop: "25px",
                justifyContent: approved.is_verified ? "center" : "flex-end",
                alignItems: "center",
                gap: "38px",
                background: approved.is_verified ? "#009D0F" : "#EAEAEA",
              }}
            >
              {approved.is_verified ? (
                <Stack spacing={1} direction="row" alignItems={"center"}>
                  {/* <GridCheckCircleIcon color="#009D0F"   style={{width:"42" , height:"42px" }}/> */}
                  <CheckCircleOutlineOutlined
                    color="#fff"
                    style={{ fill: "#fff", width: "42", height: "42px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                      borderRadius: "4px",
                      color: "#fff",
                    }}
                  >
                    Approved
                  </Typography>
                </Stack>
              ) : (
                <Stack spacing={3} direction="row" alignItems={"center"}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                      borderRadius: "4px",
                      color: "#000",
                    }}
                  >
                    Final Approval
                  </Typography>
                  <Button
                    sx={{
                      width: "230px",
                      height: "50px",
                      padding: "13px 142px",
                      borderRadius: "4px",
                      fontSize: "20px",
                      textTransform: "capitalize",
                      background: "#000",
                      color: "#fff",
                      boxSizing: "border-box",
                      cursor: "pointer",
                      ":disabled": {
                        background: "#ACACAC",
                        color: "rgba(0, 0, 0, 0.30)",
                        cursor: "not-allowed",
                      },
                      "&:hover": { backgroundColor: "#000" },
                    }}
                    disabled={isButtonDisable || !permission?.UPDATE}
                    onClick={handleApproved}
                  >
                    Approve
                  </Button>
                </Stack>
              )}
            </Box>
            <Divider />
            <br />
            <br />
            <Divider />
          </Box>
        </Box>
      )}

      <CustomConfirmationDialog
        open={openDeleteModal}
        status="delete"
        deleteById={deleteDriver}
        setOpen={setOpenDeleteModal}
        message={`Are you sure you want to delete ${basicDetails?.name}?`}
      />
      <CustomConfirmationDialog
        open={openBlockModel}
        status="block"
        blockById={blockDriverByID}
        setOpen={setOpenBlockModel}
        message={`Are you sure you want to ${
          bankDetails.accountStatus === "BLOCKED" ? "Unblock" : "block"
        } ${basicDetails?.name}?`}
      />
    </>
  );
};

export default DriverDetails;
