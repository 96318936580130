import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import logo from "../assets/logo512.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../state";
import axios from "axios";

const loginSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
});

const initialValuesLogin = {
  email: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const config = useSelector(
    (store) => store.config.configValues.server_base_url
  );

  const login = async (values, onSubmitProps) => {
    await axios.post(`${config}/crm/login/`, values).then((res) => {
      const accessToken = res?.data?.data?.tokens?.access_token ?? "";
      localStorage.setItem("token", accessToken);
      dispatch(
        setLogin({
          user: res?.data?.message ?? "",
          token: accessToken,
          refreshToken: res?.data?.data?.tokens?.refresh_token ?? "",
          is_superuser: res?.data?.data?.is_superuser ?? false,
          full_name: res?.data?.data?.full_name ?? "",
          profilepic_url: "",
          websocket_room_id: res?.data?.data?.websocket_room_id ?? "",
          roles: res?.data?.data.roles ?? [],
          role: res?.data?.data.role ?? "",
          opertor_id: res?.data?.opertor_id ?? "",
          admin_sos_websocket_room_id:
            res.data.data.admin_sos_websocket_room_id ?? null,
        })
      );

      navigate("/");

      onSubmitProps.resetForm();
    });
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await login(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesLogin}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              backgroundColor: "#F7F9FC",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "60vw",
                height: "80vh",
                backgroundColor: "white",
                m: "10%",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="white"
                gap="5px"
                mt="5%"
              >
                <Box
                  component="img"
                  alt="logo"
                  src={logo}
                  height="62px"
                  width="62px"
                  sx={{ objectFit: "contain" }}
                />
                <Typography
                  variant="h3"
                  fontWeight="0.5rem"
                  fontFamily="@Gotham"
                  color="#043A87"
                >
                  PeekUp
                </Typography>
              </Box>
              <Typography
                variant="h4"
                fontWeight="0.1rem"
                fontFamily="@Gotham"
                color="black"
                textAlign="center"
                m="15px"
              >
                PeekUp Data Monitoring System
              </Typography>
              <div
                className="paragraph"
                style={{ textAlign: "center", marginTop: "5%" }}
              ></div>
              <div className="email-field" style={{ textAlign: "center" }}>
                <TextField
                  sx={{ m: "15px", width: "40%" }}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </div>
              <div className="password-field" style={{ textAlign: "center" }}>
                <FormControl
                  sx={{ m: "15px", width: "40%" }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={
                      Boolean(touched.password) && Boolean(errors.password)
                    }
                    helperText={touched.password && errors.password}
                  />
                </FormControl>
              </div>
              {/* <div
                className="forgot-password"
                style={{ width: "70%", textAlign: "right" }}
              >
                <Typography
                  variant="p"
                  fontFamily="Proxima nova"
                  color="#043A87"
                  textAlign="center"
                  sx={{ cursor: "pointer" }}
                >
                  Forgot Password?
                </Typography>
              </div> */}
              <div className="login-btn" style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  sx={{
                    width: "40%",
                    backgroundColor: "black",
                    m: "15px",
                    "&:hover": {
                      backgroundColor: "#191919",
                    },
                  }}
                  type="submit"
                >
                  Login
                </Button>
              </div>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Login;
