import axios from "axios";
import { getFirebaseRemoteConfig } from "./getFirebaseConfig";

let persisted = localStorage.getItem("persist:root") || null;
let config;
let configObject;

if (persisted) {
  let persistObj = JSON.parse(persisted || {});

  if (persistObj?.config) {
    configObject = JSON.parse(persistObj.config);
  } else {
    configObject = null;
  }
}

if (!configObject && !configObject?.configValues?.server_base_url) {
  persisted = await getFirebaseRemoteConfig().then((res) => {
    config = res.server_base_url;
  });
} else {
  try {
    config = configObject?.configValues?.server_base_url ?? "";
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }
}

const axiosInstance = axios.create({
  baseURL: config, // Your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstance;
