// ReusableTimePicker.js
import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers";

const ReusableTimePicker = ({
  header,
  label,
  ampm = false,
  name,
  disabled = false,
  value,
  onChange = () => {},
  sx = {},
  setState,
  ...rest
}) => {
  return (
    <TimePicker
      sx={{ marginTop: header ? "18px" : "", ...sx }}
      ampm={ampm}
      disabled={disabled}
      slotProps={{ textField: { size: "small" } }}
      label={label}
      value={value ? dayjs(value) : null}
      onChange={(newValue) => onChange(name, newValue.toISOString(), setState)}
      {...rest}
    />
  );
};

ReusableTimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.object, // Optional styling prop
};

export default ReusableTimePicker;
