// Import necessary dependencies
import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
    configValues: {
      "google_api_key": null,
      "server_base_url": null,
      "websocket_server_base_url": null,
    },
};

// Create a slice
const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfigValues: (state, action) => {
      state.configValues = action.payload;
    },
  },
});

// Export the actions and reducer
export const {
  setConfigValues,
} = configSlice.actions;
export default configSlice.reducer;
