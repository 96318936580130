// queryHelpers.js

export const setQuery = (key, value, setSearchParams) => {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.set(key, value);

  setSearchParams(searchParams, { replace: true });
};

export const removeQuery = (key, setSearchParams) => {
  const searchParams = new URLSearchParams(window.location.search);


  if (searchParams.has(key)) {
    searchParams.delete(key);
    setSearchParams(searchParams, { replace: true });
  }
};
